import React, { useState } from 'react';
import { Grid, Button } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Fields } from '../../components';
import { signup, sendEmailAgain } from '../../actions';
import logo from '../../assets/images/logo-reestock.png';

const Signup = ({ history, signup }) => {
  const [error, setError] = useState('');
  const [isWaiting, setIsWaiting] = useState(false);

  const handleSignup = async () => {
    const result = await signup();

    if (result.success) {
      setIsWaiting(true);
      setError(false);
    } else {
      setError(result.msg);

      setTimeout(() => setError(''), 15000);
    }
  };

  return (
    <div className="login">
      <div className="login-images">
        <img src={logo} alt="logo" className="logo" />
      </div>
      <div className="login-form__container">
        <div className="login-form">
          <h1 className="title">Registrate</h1>
          <h2 className="subtitle">¡Bienvenido a reestock!</h2>
          <Grid divided="vertically">
            <Grid.Row>
              <Grid.Column>
                <div style={{ marginTop: 30 }}>
                  {error && (
                    <span
                      style={{ color: 'red', fontWeight: 'bold', fontSize: 12 }}
                    >
                      {error}
                    </span>
                  )}

                  {isWaiting && (
                    <>
                      <span>
                        Te enviamos un correo, por favor válida la información
                        para continuar
                      </span>
                      <br />

                      <span>¿No recibiste el correo? </span>
                      <a href="#" onClick={() => sendEmailAgain()}>
                        Enviar de nuevo
                      </a>
                    </>
                  )}

                  {!isWaiting && (
                    <Form
                      form="signupForm"
                      onSubmit={handleSignup}
                      initialValues={{
                        name: '',
                        email: '',
                        phone: '',
                        username: '',
                        password: '',
                        confirm: '',
                        terms: false,
                      }}
                    >
                      <Fields
                        fieldsData={{
                          name: {
                            name: 'name',
                            label: 'NOMBRE',
                            placeholder: '',
                            type: 'textInline',
                            required: true,
                            disabled: false,
                          },
                          email: {
                            name: 'email',
                            label: 'EMAIL',
                            placeholder: '',
                            type: 'textInline',
                            required: true,
                            disabled: false,
                          },
                          phone: {
                            name: 'phone',
                            label: 'CELULAR',
                            placeholder: '',
                            type: 'textInline',
                            required: true,
                            disabled: false,
                          },
                          username: {
                            name: 'username',
                            label: 'USUARIO',
                            placeholder: '',
                            type: 'textInline',
                            required: true,
                            disabled: false,
                          },
                          password: {
                            name: 'password',
                            label: 'CONTRASEÑA',
                            placeholder: '',
                            type: 'textInline',
                            typeInput: 'password',
                            required: true,
                            disabled: false,
                          },
                          confirm: {
                            name: 'confirm',
                            label: 'CONFIRMAR',
                            placeholder: '',
                            type: 'textInline',
                            typeInput: 'password',
                            required: true,
                            disabled: false,
                          },
                          recordarme: {
                            name: 'terms',
                            label: 'Acepto los terminos y condiciones',
                            placeholder: '',
                            toggle: false,
                            type: 'checkbox',
                            required: false,
                            disabled: false,
                            styleContainer: {
                              marginTop: 40,
                              position: 'relative',
                            },
                          },
                        }}
                      />

                      <div>
                        <Button className="button-login" type="submit" circular>
                          REGISTRATE
                        </Button>
                      </div>
                    </Form>
                  )}

                  <div className="singup-link">
                    <span>¿Ya tienes cuenta?</span>
                    <Button
                      className="signup-button"
                      fluid
                      basic
                      circular
                      color="black"
                      onClick={() => history.push('/login')}
                    >
                      INICIA SESIÓN EN REESTOCK
                    </Button>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  signup: () => dispatch(signup()),
  sendEmailAgain: () => dispatch(sendEmailAgain()),
});

export default connect(null, mapDispatchToProps)(withRouter(Signup));
