import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Icon,
  Header,
  Container,
  Divider,
  Table,
  Modal,
} from 'semantic-ui-react';
import { createClient } from '../../actions';
import usePermissions from '../../hooks/usePermissions';
import APIclient from '../../api';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import ClientProfileNewPaymentMethod from './newPaymentMethod';
import axios from 'axios';
const qs = require('qs');
const promise = loadStripe(
  'pk_live_LJ5am041kiGkuXQKFpmMkeS000hY5DpI60'
);


const ClientProfilePaymentMethods = ({ state, history }) => {
  const feathersService = APIclient.service('/api/clients');
  const paginate = 20;
  const initialValues = state.users.user;
  const [hasPermission] = usePermissions(window.location.pathname, history);
  const [loadedClients, setLoadedClients] = useState([]);
  const [editorOpen, setEditorOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const [editID, setEditID] = useState('');

  useEffect(() => {
    loadClientsData();
  }, [loadedClients.length != 0]);

  const loadClientsData = (activePage = 0) => {
    (async () => {
      const loadClients = await feathersService
        .find({
          query: {
            user: initialValues._id,
            $limit: paginate,
            $skip: activePage * paginate,
          },
        })
        .then((loadClients) => {
          setLoadedClients(loadClients.data);
        });
    })();
  };

  const handleEditPayment = (index, id) => {
    setEditIndex(index);
    setEditID(id);
    setEditorOpen(true);
  };

  const handleNewPayment = () => {
    setEditIndex(-1);
    setEditorOpen(true);
  };

  const deleteStripePaymentMethod = async (paymentID) => {
    (async () => {
      const url = 'https://api.reestock.com.mx/remove-card-customer';
      const data = { 'card': paymentID };
      const options = {
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify(data),
        url,
      };
      await axios(options);
      handleDeletePaymentMethodToClient(paymentID);
    })();
  }

  const handleDeletePaymentMethodToClient = (paymentID) => {
    let patchData = loadedClients[0];
    console.log( patchData.paymentmethods);   
    patchData.paymentmethods =patchData.paymentmethods.filter(function(obj){return obj.id!==paymentID})
    console.log( patchData.paymentmethods);
     (async () => {
     const response = await feathersService
        .patch(patchData._id,patchData)
        .then((response) => {
          loadClientsData();
        });
      
    })();
      
  };

  return (
    <div>
      {hasPermission && (
        <Container>
          <Header as="h2">
            Métodos de pago
            <Button color="red" floated="right" onClick={handleNewPayment}>
              <Icon name="add" className="detailIcon" />
              &nbsp;Agregar tarjeta
            </Button>
          </Header>
          <Divider />
          <Table singleLine striped style={{ marginTop: 20 }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Descripción</Table.HeaderCell>
                <Table.HeaderCell>Últimos 4 dígitos</Table.HeaderCell>
                <Table.HeaderCell>Válida hasta</Table.HeaderCell>
                <Table.HeaderCell collapsing={true}>Acciones</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {loadedClients.length != 0 &&
                loadedClients[0].paymentmethods ? (
                loadedClients[0].paymentmethods.map((payment, index) => (                 
                  <Table.Row key={payment._id}>
                    <Table.Cell
                      children={payment.card.brand}
                      collapsing={false}
                    ></Table.Cell>
                    <Table.Cell
                      children={payment.card.last4}
                      collapsing={false}
                    ></Table.Cell>
                    <Table.Cell
                      children={payment.card.exp_month + '/' + payment.card.exp_year}
                      collapsing={false}
                    ></Table.Cell>
                    <Table.Cell>
                      <Button
                        circular
                        icon="trash"
                        size="mini"
                        color="red"
                        data={payment._id}
                        onClick={() => deleteStripePaymentMethod(payment.id)}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell></Table.Cell>
                  <Table.Cell>Sin información</Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>

          {editorOpen && (
            <Elements stripe={promise}>
              <ClientProfileNewPaymentMethod
                open={editorOpen}
                onClose={setEditorOpen}
                clientData={loadedClients[0]}
                editIndex={editIndex}
                editPaymentID={editID}
                setEditorOpen={setEditorOpen}
                userData={initialValues}
              />
            </Elements>

          )}
        </Container>
      )}
      {!hasPermission && <div>No disponible.</div>}
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  FormUsers: state.form.FormUsers?.values ?? null,
  props: props,
  state: state,
});

const mapDispatchToProps = (dispatch) => ({
  createClient: () => dispatch(createClient()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientProfilePaymentMethods);
