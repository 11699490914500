import React from 'react';
import {
  DateInput,
  TimeInput,
  DateTimeInput,
  DatesRangeInput,
} from 'semantic-ui-calendar-react';

const Calendar = ({
  meta,
  input: { onChange, ...restPropsInput },
  placeholder,
  label,
  typeCalendar = 'date',
  disabled,
  iconPosition = 'left',
}) => {
  return (
    <>
      {typeCalendar === 'date' && (
        <DateInput
          closable
          error={meta.error && meta.touched ? meta.error : false}
          label={label}
          {...restPropsInput}
          placeholder={placeholder}
          iconPosition={iconPosition}
          onChange={(_, { value }) => onChange(value)}
          disable={disabled}
          animation="none"
        />
      )}

      {/* {typeCalendar === 'time' && (
        <TimeInput
          name="time"
          placeholder="Time"
          value={this.state.time}
          iconPosition="left"
          onChange={this.handleChange}
        />
      )}

      {typeCalendar === 'datetime' && (
        <DateTimeInput
          name="dateTime"
          placeholder="Date Time"
          value={this.state.dateTime}
          iconPosition="left"
          onChange={this.handleChange}
        />
      )}

      {typeCalendar === 'daterange' && (
        <DatesRangeInput
          name="datesRange"
          placeholder="From - To"
          value={this.state.datesRange}
          iconPosition="left"
          onChange={this.handleChange}
        />
      )} */}
    </>
  );
};

export default React.memo(Calendar);
