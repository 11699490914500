//  FeathersJS
import feathers from '@feathersjs/client';
// import socketio from '@feathersjs/socketio-client'
import io from 'socket.io-client';
// import auth from '@feathersjs/authentication-client'

const baseUrl = 'https://api.reestock.com.mx/';
const socket = io(baseUrl);
const client = feathers();

client
  .configure(feathers.socketio(socket, { timeout: 50000 }))
  .configure(feathers.activateHooks);
client.configure(
  feathers.authentication({
    storage: window.localStorage,
    cookie: 'reestock-jwt',
    storageKey: 'reestock-jwt',
  })
);

export default client;
