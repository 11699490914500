import styled from 'styled-components';

export const InputContainer = styled.div`
  width: 100%;
  position: relative;
  font-weight: bold;
`;

export const Label = styled.label`
  position: absolute;
  top: 10px;
  left: 6px;
  font-size: 12px;
`;

export const Input = styled.input`
  border: none !important;
  border-bottom: 1px solid #aaa !important;
  padding-left: 100px !important;
  padding-right: 20px !important;
  border-radius: 0 !important;
`;
