import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'semantic-ui-react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { Player } from '@lottiefiles/react-lottie-player';
import logo from '../../../assets/images/logo-reestock.png';
import moment from 'moment';

const Ticket = ({ isOpen, onClose, ticket: info }) => {
  const [showAnimation, setShowAnimation] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowAnimation(false);
    }, 2000)
  }, []);

  return (
    <Modal open={isOpen} onClose={() => onClose(false)} closeIcon>
      <Modal.Content>
        {showAnimation && 
          <Player
            autoplay
            src="https://assets3.lottiefiles.com/packages/lf20_7W0ppe.json"
            style={{ margin: '0 auto', height: '600px', width: '600px' }}
          />
        }
        {!showAnimation && <DocumentPDF info={info} />}
      </Modal.Content>
    </Modal>
  );
};

const DocumentPDF = ({ info }) => {
  const { ticket: { payment, paymentMethod, total }} = info
  const status = payment.data.msg === 'success' ? 'Exitoso' : 'Negativo'
  const id = payment.data?.paymentId?.id

  return (
    <Document>
      <Page size="A4" style={styles.page}>
      <View style={styles.section}>
          <View style={styles.column}>
            <View style={{ display: 'flex', flexDirection: 'column' }}>
              <View style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                <View style={styles.logoContainer}>
                  <img src={logo} alt="logo" style={styles.logo} />
                </View>
              </View>
              <View style={{ display: 'flex', justifyContent: 'center', padding: 10 }}>
                <Text style={styles.bigTitle}>Recibo de pago</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.section}>
          <View style={styles.column}>
            <Text style={styles.title}>Fecha y hora de pago:</Text>
            <Text style={{ fontWeight: 'bold' }}>{moment().format('DD/MM/YYYY hh:mm:ss')}</Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.title}>ID de transacción:</Text>
            <Text style={{ fontWeight: 'bold' }}>{id}</Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.title}>Estatus:</Text>
            <Text style={{ fontWeight: 'bold' }}>{status}</Text>
          </View>
        </View>
        <View style={styles.section}>
          <View style={styles.column}>
            <Text style={styles.title}>Concepto:</Text>
            <Text style={{ fontWeight: 'bold' }}>Pago de gas</Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.title}>Su forma de pago:</Text>
            <Text style={{ fontWeight: 'bold' }}>Tarjeta</Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.title}>Su pago:</Text>
            <Text style={{ fontWeight: 'bold' }}>$ {parseFloat(total).toFixed(2)}</Text>
          </View>
        </View>

        <View style={styles.divider} />
        
        <View style={styles.section}>
          <View style={styles.column}>
            <View style={styles.infoReestock}>
              <Text style={styles.infoReestockTitle}>
                ReeStock Mexicana SAPI de C.V.
              </Text>
              <Text>Avenida Kiki Murillo 102 – 28 La Primavera</Text>
              <Text>CP 80300 Culiacán, Sinaloa, MX</Text>
              <Text>Teléfono (667) 189 3640 RFC: RME-171115-JP8</Text>
            </View>
          </View>
          <View style={styles.column}>
            <Text style={styles.thankyou}>¡GRACIAS POR SU PAGO!</Text>
          </View>
        </View>
      </Page>
    </Document>
  )
}

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column'
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    marginTop: 10,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 100,
    height: 100,
    borderRadius: 50,
    backgroundColor: '#e03a3e'
  },
  logo: {
    width: 68,
    height: 14
  },
  bigTitle: {
    color: '#e03a3e',
    fontWeight: 'bold',
    fontSize: 24
  },
  infoReestock: {
    display: 'flex',
    flex: 1,
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    fontSize: 12,
    color: '#173148',
  },
  infoReestockTitle: {
    fontSize: 14,
  },
  thankyou: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#173148',
    textAlign: 'right'
  },
  title: {
    color: '#173148',
    fontSize: 14,
    fontWeight: 'bold',
  },
  datePayment: {
    fontWeight: 'bold',
    fontSize: 18,
    textAlign: 'right',
  },
  amountPayment: {
    fontWeight: 'bold',
    fontSize: 24,
    textAlign: 'right',
  },
  displayFlex: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  detail: {
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid #c4c4c4',
    paddingTop: 20,
    paddingBottom: 20,
    marginTop: 20
  },
  detailRow: {
    display: 'flex',
    flex: 1,
    padding: '0px 20px',
  },
  detailRowFlex: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  detailLeft: {
    display: 'flex',
    flex: 2,
    justifyContent: 'flex-end'
  },
  detailRight: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
  },
  divider: {
    marginTop: 10,
    width: '100%',
    height: 8,
    backgroundColor: 'red',
  }
});

export default Ticket;
     