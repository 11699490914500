import React from 'react';
import { InputContainer, Label, Input } from './styled.components';

const InputInline = ({
  meta,
  input,
  typeInput = 'text',
  placeholder,
  label,
  required,
  disabled,
}) => (
  <InputContainer>
    <Label>{label}</Label>
    <Input
      label={required ? `${label}(*)` : label}
      error={meta.error && meta.touched ? meta.error : false}
      {...input}
      autoComplete="off"
      type={typeInput}
      placeholder={placeholder}
      disabled={disabled}
    />
  </InputContainer>
);

export default React.memo(InputInline);
