import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Header,
  Container,
  Divider,
  Table,
} from 'semantic-ui-react';
import { createClient } from '../../actions';
import usePermissions from '../../hooks/usePermissions';
import APIclient from '../../api';
import ClientProfileService from './editservices';
//import history from './history';

const ClientProfileServiceList = ({ state, history }) => {
  const feathersService = APIclient.service('/api/clients');
  const servicesService = APIclient.service('/api/services');
  const paginate = 20;
  const initialValues = state.users.user;
  const [hasPermission] = usePermissions(window.location.pathname, history);
  const [loadedClients, setLoadedClients] = useState([]);
  const [editorOpen, setEditorOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const [editID, setEditID] = useState('');
  const [clientServices, setClientServices] = useState([]);

  useEffect(() => {
    loadData();
    // loadClientsServices();
  }, []);

  const loadData = async (activePage = 0) => {
    const client = await feathersService
    .find({
      query: {
        user: initialValues._id,
      },
    })

    setLoadedClients(client.data[0])

    const services = await servicesService
    .find({
      query: {
        client: client.data[0]._id,
        $limit: paginate,
        $skip: activePage * paginate,
      },
    })

    setClientServices(services.data);
  }

  const loadClientsServices = (activePage = 0) => {
    (async () => {
      const loadClientServices = await servicesService
        .find({
          query: {
            client: loadedClients._id,
            $limit: paginate,
            $skip: activePage * paginate,
          },
        })
        .then((loadClientServices) => {
          setClientServices(loadClientServices.data);
        });
    })();
  };

  const handleEditService = (index, id) => {
    setEditIndex(index);
    setEditID(id);
    setEditorOpen(true);
  };

  return (
    <div>
      {hasPermission && (
        <Container>
          <Header as="h2">Servicios contratados</Header>
          <Divider />
          <Table singleLine striped style={{ marginTop: 20 }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Servicio</Table.HeaderCell>
                <Table.HeaderCell>Descripción</Table.HeaderCell>
                <Table.HeaderCell collapsing={true}>Acciones</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {clientServices && clientServices.length != 0 ? (
                clientServices.map((service, index) => (
                  <Table.Row key={service._id}>
                    <Table.Cell
                      children={service.type}
                      collapsing={false}
                    ></Table.Cell>
                    <Table.Cell
                      children={service.comments}
                      collapsing={false}
                    ></Table.Cell>
                    <Table.Cell>
                      <Button
                        circular
                        icon="pencil"
                        size="mini"
                        color="green"
                        data={service._id}
                        onClick={() => handleEditService(index, service._id)}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell></Table.Cell>
                  <Table.Cell>Sin información</Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>

          {editorOpen && (
            <ClientProfileService
              open={editorOpen}
              onClose={setEditorOpen}
              clientData={loadedClients}
              clientService={clientServices}
              editIndex={editIndex}
              editLocationID={editID}
              setEditorOpen={setEditorOpen}
              loadServices = {loadClientsServices}
            />
          )}
        </Container>
      )}
      {!hasPermission && <div>No disponible.</div>}
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  FormUsers: state.form.FormUsers?.values ?? null,
  props: props,
  state: state,
});

const mapDispatchToProps = (dispatch) => ({
  createClient: () => dispatch(createClient()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientProfileServiceList);
