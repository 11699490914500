import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Icon } from 'semantic-ui-react'
import { Form, Fields } from '../../components';
import APIclient from '../../api';


const ClientProfileService = ({ props, open: isOpen,
    onClose, clientProfileService }) => {
    let clientData = props.clientData;
    let billingInfo = props.clientData.billingInformation;
    let paymentmethods = props.clientData.paymentmethods;
    let editIndex = props.editIndex;
    let clientService = props.clientService[editIndex];
    
    let editBI_ID = props.editBI_ID;
    let editValues = null;
     if (clientService) {
         editValues = clientService ?? null;
     }
    const feathersService = APIclient.service('/api/services');

    const handleSave = () => {
       /* console.log(editValues);
        console.log("----",clientProfileService)
        if (editValues == null) {
            if (!clientData.billingInformation) {
                clientData.billingInformation = [];
            }
            clientData.push(clientProfileService);
        } else {
            const newState = clientData.map(obj =>
                obj._id == editBI_ID ? clientProfileService : obj
            );
            clientData = [];
            clientData = newState;
        }*/

        (async () => {
            const response = await feathersService.patch(clientProfileService._id, clientProfileService).then(response => {
                props.loadServices();
                props.setEditorOpen(false);
            });
        })();
    }

    function parseBI() {       
        if(billingInfo){
            return billingInfo.map((BIs) => {
                return { key: BIs._id, text: BIs.rfc, value: BIs._id };
            });
        }else{
            return[];
        }       
    }

    function parsePaymentMethods() {
        if(paymentmethods){
            return paymentmethods.map((PM) => {
                return { key: PM.id, text: PM.card.brand + ' ' + PM.card.last4, value: PM.id };
            });
        }else{
            return []
        }
       
    }

    return (
        <Modal
            open={isOpen}
            closeIcon
            closeOnEscape={true}
            closeOnDimmerClick={true}
            onClose={() => onClose(false)}
        >
            <Modal.Header>
                {editBI_ID === '' ? 'Agregar domicilio' : 'Información del servicio contratado'}
            </Modal.Header>
            <Modal.Content>
                <Form form="clientProfileService" initialValues={editValues}>
                    <Fields
                        fieldsData={{
                            iotID: {
                                name: 'iotID',
                                label: 'Número de contrato',
                                type: 'text',
                                required: false,
                                disabled: true
                            },
                            contractStart: {
                                name: 'contractStart',
                                label: 'Inicio del contrato',
                                type: 'text',
                                required: false,
                                disabled: true
                            },
                            billinginfoid: {
                                name: 'billinginfoid',
                                label: 'Información de facturación',
                                placeholder: 'Seleccione la información de facturación',
                                type: 'select',
                                options: parseBI(),
                                required: false,
                                disabled: false
                            },
                            paymentMethod: {
                                name: 'paymentMethod',
                                label: 'Método de pago',
                                placeholder: 'Seleccione el método de pago',
                                type: 'select',
                                options: parsePaymentMethods(),
                                required: false,
                                disabled: false,
                            },
                            comments: {
                                name: 'comments',
                                label: 'Descripción',
                                placeholder: 'Información adicional',
                                type: 'textarea',
                                required: false,
                                disabled: false
                            },
                            automaticbilling: {
                                name: 'automaticbilling',
                                label: 'Pago domiciliado',
                                placeholder: 'Pago domiciliado',
                                type: 'checkbox',
                                required: false,
                                disabled: false
                            }
                        }}
                    />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => onClose(false)}>Cancelar</Button>
                <Button color="red" onClick={handleSave}>
                    <Icon name="save" className="detailIcon" />
          &nbsp;Guardar
        </Button>
                <br />
            </Modal.Actions>
        </Modal>
    );
};

const mapStateToProps = (state, props) => ({
    clientProfileService: state.form.clientProfileService?.values ?? null,
    props: props
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(ClientProfileService);

