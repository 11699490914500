import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Icon } from 'semantic-ui-react';
import { Form, Fields } from '../../components';
import APIclient from '../../api';

const ClientProfileLocation = ({
  open: isOpen,
  onClose,
  props,
  clientProfileLocation,
}) => {
  let clientData = props.clientData;
  let editIndex = props.editIndex;
  let editLocationID = props.editLocationID;
  let editValues = clientData.locations[editIndex] ?? null;
  const feathersService = APIclient.service('/api/clients');


  const handleSave = () => {
    if (editValues == null) {
      clientData.locations.push(clientProfileLocation);
    } else {
      const newState = clientData.locations.map((obj) =>
        obj._id === editLocationID ? clientProfileLocation : obj
      );
      clientData.locations = [];
      clientData.locations = newState;
    }

    (async () => {
      const response = await feathersService
        .patch(clientData._id, clientData)
        .then((response) => {
          props.setEditorOpen(false);
        });
    })();
  };

  return (
    <Modal
      open={isOpen}
      closeIcon
      closeOnEscape={true}
      closeOnDimmerClick={true}
      onClose={() => onClose(false)}
    >
      <Modal.Header>
        {editLocationID === '' ? 'Agregar domicilio' : 'Editar domicilio'}
      </Modal.Header>
      <Modal.Content>
        <Form form="clientProfileLocation" initialValues={editValues}>
          <Fields
            fieldsData={{
              name: {
                name: 'name',
                label: 'Nombre del domicilio ',
                placeholder: 'Nombre del domicilio',
                type: 'text',
                required: false,
                disabled: false,
                columns: 7,
              },
              phone: {
                name: 'phone',
                label: 'Teléfono',
                placeholder: 'Télefono',
                type: 'text',
                required: false,
                disabled: false,
                columns: 8,
              },
              street: {
                name: 'street',
                label: 'Calle',
                placeholder: 'Calle',
                type: 'text',
                required: false,
                disabled: false,
                columns: 7,
              },
              number: {
                name: 'number',
                label: 'Número',
                placeholder: 'Número',
                type: 'text',
                required: false,
                disabled: false,
                columns: 4,
              },
              internalNumber: {
                name: 'internalNumber',
                label: 'Número interior',
                placeholder: 'Número interior',
                type: 'text',
                required: false,
                disabled: false,
                columns: 4,
              },
              suburb: {
                name: 'suburb',
                label: 'Colonia',
                placeholder: 'Colonia',
                type: 'text',
                required: false,
                disabled: false,
                columns: 7,
              },
              zipCode: {
                name: 'zipCode',
                label: 'Código postal',
                placeholder: 'Código postal',
                type: 'text',
                required: false,
                disabled: false,
                columns: 4,
              },
              city: {
                name: 'city',
                label: 'Ciudad',
                placeholder: 'Ciudad',
                type: 'text',
                required: false,
                disabled: false,
                columns: 7,
              },
              state: {
                name: 'state',
                label: 'Estado',
                placeholder: 'Estado',
                type: 'text',
                required: false,
                disabled: false,
                columns: 7,
              },
              references: {
                name: 'references',
                label: 'Referencias',
                placeholder: 'Referencias',
                type: 'textarea',
                required: false,
                disabled: false,
                columns: 15,
              },
              additionalinfo: {
                name: 'radditionalinfo',
                label: 'Informacion adicional',
                placeholder: 'información',
                type: 'textarea',
                required: false,
                disabled: false,
                columns: 15,
              },
            }}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => onClose(false)}>Cancelar</Button>
        <Button color="red" onClick={handleSave}>
          <Icon name="save" className="detailIcon" />
          &nbsp;Guardar Domicilio
        </Button>
        <br />
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps = (state, props) => ({
  clientProfileLocation: state.form.clientProfileLocation?.values ?? null,
  props: props,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientProfileLocation);
