import { combineReducers } from 'redux';
import { reducer as notifications } from 'react-notification-system-redux';
import users from './users';
import app from './app';
import editclient from './editclient';
import clients from './clients';
import { reducer as formReducer } from 'redux-form';

export default combineReducers({
  app,
  notifications,
  users,
  editclient,
  clients,
  form: formReducer,
});
