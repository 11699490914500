import React from 'react';
import { Dropdown, Image, Divider } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo-reestock.png';
import { useHistory } from 'react-router-dom';
import userDefault from '../assets/images/user-default.jpeg';

const Header = ({ user, logout, type }) => {
  const history = useHistory();
  const renderUser = () => {
    return (
      <span>
        <Image avatar src={userDefault} />
        <span className="menu-user--name">{user}</span>
      </span>
    );
  };

  const handleClickSignout = async () => {
    history.push('/');
    await logout();
  };

  const handleItemClick = (e, { to }) => {
    history.push(to);
  };

  return (
    <div className="header-app">
      <div className="header-logo">
        <Link to="/">
          <img src={logo} alt="logo" className="logo" />
        </Link>
      </div>
      <div className="header-user">
        <div className="user-container">
          <Dropdown item trigger={renderUser()} float="right">
            <Dropdown.Menu>
              {/* <Dropdown.Item
                name="profile"
                to="/perfil"
                onClick={handleItemClick}
              >
                Perfil
              </Dropdown.Item> */}
              {type === 'customer' &&
                <>
                  <Dropdown.Item
                    name="payments"
                    to="/perfil/metodospago"
                    onClick={handleItemClick}
                  >
                    Método de pago
                  </Dropdown.Item>
                  <Dropdown.Item
                    name="billinginfo"
                    to="/perfil/facturacion"
                    onClick={handleItemClick}
                  >
                    Facturación
                  </Dropdown.Item>
                  <Dropdown.Item
                    name="address"
                    to="/perfil/domicilios"
                    onClick={handleItemClick}
                  >
                    Domicilios
                  </Dropdown.Item>
                  <Dropdown.Item
                    name="services"
                    to="/perfil/servicios"
                    onClick={handleItemClick}
                  >
                    Servicios
                  </Dropdown.Item>
                </>
              }
              <Divider />
              <Dropdown.Item
                name="singout"
                to="/singout"
                onClick={handleClickSignout}
              >
                Cerrar Sesión
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Header);
