import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Icon } from 'semantic-ui-react';
import { Form, Fields } from '../../components';
import APIclient from '../../api';

const ClientProfileBillingInfo = ({
  open: isOpen,
  onClose,
  props,
  clientProfileBillingInfo,
}) => {
  let clientData = props.clientData;
  let editIndex = props.editIndex;
  let editBI_ID = props.editBI_ID;
  let editValues = null;
  if (clientData.billingInformation) {
    editValues = clientData.billingInformation[editIndex] ?? null;
  }
  const feathersService = APIclient.service('/api/clients');

  const handleSave = () => {
    if (editValues == null) {
      if (!clientData.billingInformation) {
        clientData.billingInformation = [];
      }
      clientData.billingInformation.push(clientProfileBillingInfo);
    } else {
      const newState = clientData.billingInformation.map((obj) =>
        obj._id === editBI_ID ? clientProfileBillingInfo : obj
      );
      clientData.billingInformation = [];
      clientData.billingInformation = newState;
    }

    (async () => {
      const response = await feathersService
        .patch(clientData._id, clientData)
        .then((response) => {
          props.setEditorOpen(false);
        });
    })();
  };

  return (
    <Modal
      open={isOpen}
      closeIcon
      closeOnEscape={true}
      closeOnDimmerClick={true}
      onClose={() => onClose(false)}
    >
      <Modal.Header>
        {editBI_ID === ''
          ? `Agregar información de facturación`
          : `Editar información de facturación`}
      </Modal.Header>
      <Modal.Content>
        <Form form="clientProfileBillingInfo" initialValues={editValues}>
          <Fields
            fieldsData={{
              type: {
                name: 'type',
                label: 'Tipo de persona',
                placeholder: 'Seleccione el tipo de persona',
                type: 'select',
                required: false,
                disabled: false,
                options: [
                  { key: 'Moral', value: 'Moral', text: 'Moral' },
                  { key: 'Fisica', value: 'Fisica', text: 'Física' },
                ],
                columns: 5,
              },
              rfc: {
                name: 'rfc',
                label: 'RFC',
                placeholder: 'RFC',
                type: 'text',
                required: false,
                disabled: false,
                columns: 10,
              },
              name1: {
                name: 'name1',
                label: 'Primer nombre',
                placeholder: 'Primer nombre',
                type: 'text',
                required: true,
                disabled: false,
                columns: 5,
              },
              name2: {
                name: 'name2',
                label: 'Segundo nombre',
                placeholder: 'Segundo nombre',
                type: 'text',
                required: false,
                disabled: false,
                columns: 5,
              },
              lastName1: {
                name: 'lastName1',
                label: 'Primer apellido',
                placeholder: 'Primer apellido',
                type: 'text',
                required: true,
                disabled: false,
                columns: 5,
              },
              lastName2: {
                name: 'lastName2',
                label: 'Segundo apellido',
                placeholder: 'Segundo apellido',
                type: 'text',
                required: false,
                disabled: false,
                columns: 5,
              },
              phone: {
                name: 'phone',
                label: 'Teléfono',
                placeholder: 'Télefono',
                type: 'text',
                required: false,
                disabled: false,
                columns: 5,
              },
              street: {
                name: 'street',
                label: 'Calle',
                placeholder: 'Calle',
                type: 'text',
                required: false,
                disabled: false,
                columns: 7,
              },
              number: {
                name: 'number',
                label: 'Número',
                placeholder: 'Número',
                type: 'text',
                required: false,
                disabled: false,
                columns: 4,
              },
              internalNumber: {
                name: 'internalNumber',
                label: 'Número interior',
                placeholder: 'Número interior',
                type: 'text',
                required: false,
                disabled: false,
                columns: 4,
              },
              suburb: {
                name: 'suburb',
                label: 'Colonia',
                placeholder: 'Colonia',
                type: 'text',
                required: false,
                disabled: false,
                columns: 7,
              },
              zipCode: {
                name: 'zipCode',
                label: 'Código postal',
                placeholder: 'Código postal',
                type: 'text',
                required: false,
                disabled: false,
                columns: 3,
              },
              city: {
                name: 'city',
                label: 'Ciudad',
                placeholder: 'Ciudad',
                type: 'text',
                required: false,
                disabled: false,
                columns: 7,
              },
              state: {
                name: 'state',
                label: 'Estado',
                placeholder: 'Estado',
                type: 'text',
                required: false,
                disabled: false,
                columns: 7,
              },
              references: {
                name: 'references',
                label: 'Referencias',
                placeholder: 'Referencias',
                type: 'textarea',
                required: false,
                disabled: false,
                columns: 15,
              },
              additionalInfo: {
                name: 'additionalInfo',
                label: 'Información adicional',
                placeholder: 'Información adicional',
                type: 'textarea',
                required: false,
                disabled: false,
                columns: 15,
              },
            }}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => onClose(false)}>Cancelar</Button>
        <Button color="red" onClick={handleSave}>
          <Icon name="save" className="detailIcon" />
          &nbsp;Guardar
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps = (state, props) => ({
  clientProfileBillingInfo: state.form.clientProfileBillingInfo?.values ?? null,
  props: props,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientProfileBillingInfo);
