import React from 'react';
import usePermissions from '../../hooks/usePermissions';
import Customer from './customer';
import Admin from './admin';

const Tickets = ({ location, history }) => {
  const [hasPermission, type] = usePermissions(location.pathname, history);

  const renderTickets = () => {
    if (type === 'customer') {
      return <Customer />;
    } else {
      return <Admin />;
    }
  };

  return <div>{hasPermission && renderTickets()}</div>;
};

export default Tickets;
