import React from 'react';
import { Field } from 'redux-form';
import FieldForm from './Field';
import { Grid } from 'semantic-ui-react';
import { getValidateFunction } from '../../utils/validations';

const Fields = ({ fieldsData, onChange }) => (
  <Grid>
    {Object.keys(fieldsData).map((key, i) => {
      const {
        optional,
        required,
        name,
        columns = 16,
        ...restPropsForm
      } = fieldsData[key];
      const validate = required ? getValidateFunction(key) : undefined;

      return (
        <Grid.Column width={columns}>
          <Field
            component={FieldForm}
            key={i}
            {...restPropsForm}
            required={required}
            validate={validate}
            name={name}
            onChange={(value) => {
              if (onChange) onChange({ name, value });
            }}
          />
        </Grid.Column>
      );
    })}
  </Grid>
);

export default Fields;
