import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Modal, Checkbox, Select } from 'semantic-ui-react';
import APIclient from '../../../api';
import './checkout.css';

const CheckoutForm = ({
  isOpen,
  onClose,
  total = 0,
  user,
  services: { serviceId },
  client,
  createPayment,
  createStripeClient,
  createStripePaymentMethod,
  handleLoadServicesAgain
}) => {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [saveMethodPayment, setSaveMethodPayment] = useState(false);
  const [automaticBilling, setAutomaticBilling] = useState(false);
  const [newCard, setNewCard] = useState(client.paymentmethods.length === 0);
  const [methodSelected, setMethodSelected] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  const cardStyle = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        borderRadius: 4,
        fontSize: '16px',
        '::placeholder': {
          color: '#32325d',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  const handleSelectMethod = (value) => {
    setMethodSelected(value);
    setDisabled(false);
  }

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);

    const paymentMethod = client.paymentmethods.filter(method => method.id === methodSelected)

    const { stripeUserID } = client;

    const clientData = {
      ...client,
      name: user.name,
      email: user.email
    }

    if (newCard) {
      if (stripeUserID) {
        const card = await createStripePaymentMethod(
          clientData,
          stripeUserID,
          saveMethodPayment,
          stripe,
          elements,
          CardElement
        )

        if (card !== undefined && card){
	  const response = await createPayment(
            stripeUserID,
            card,
            serviceId,
            automaticBilling
          );

          if (response.success) {
            onClose({ payment: response, paymentMethod, total });
            handleLoadServicesAgain();
          }
        }
        else {
	  setProcessing(false);
	  alert('No se pudo procesar el pago, intenta de nuevo mas tarde.');
	}
      }
      else {
        const customer = await createStripeClient(clientData)
        const card = await createStripePaymentMethod(
          clientData,
          customer,
          saveMethodPayment,
          stripe,
          elements,
          CardElement
        )

        const response = await createPayment(
          customer,
          card,
          serviceId,
          automaticBilling
        );

        if (response.success) {
          onClose({ payment: response, paymentMethod, total });
          handleLoadServicesAgain();
        }
      }
    }
    else {
      const response = await createPayment(
        stripeUserID,
        methodSelected,
        serviceId,
        automaticBilling
      );

      if (response.success) {
        onClose({ payment: response, paymentMethod, total });
        handleLoadServicesAgain();
      }
    }
  };

  return (
    <Modal size="small" open={isOpen} onClose={() => onClose(false)}>
      <Modal.Header>Ingrese sus datos para realizar el pago</Modal.Header>
      <Modal.Content>
        <form id="payment-form" onSubmit={handleSubmit}>

          {!newCard && 
            <>
              <Select
                fluid
                onChange={(_, { value }) => handleSelectMethod(value)}
                placeholder="Seleccione el metodo de pago"
                options={client.paymentmethods.map(({ id, card }) => {
                  return card.brand !== undefined ? 
                  { key: id, text: `${card.brand} **** ${card.last4}`, value: id } : { key: 'no_valid', value: 'no_valid', text: 'No valida' }
                }
                )}/>

              <br />
              <a href="#" onClick={() => setNewCard(true)}>Agregar nuevo metodo de pago</a>
              <br />
            </>
          }

          {newCard && 
            <>
              {client.paymentmethods.length > 0 && 
                <>
                  <a href="#" onClick={() => setNewCard(false)}>Seleccionar metodo de pago existente</a>
                  <br />
                  <br />
                </>
              }
        
              <CardElement
                id="card-element"
                options={cardStyle}
                onChange={handleChange}
              />
            </> 
          }

          <br />

          {newCard && 
            <>
              <Checkbox
                label='Guardar tarjeta'
                onClick={() => setSaveMethodPayment(!saveMethodPayment)} />
                <br />
                <br />
            </>
          }
          
          <Checkbox
            onClick={() => setAutomaticBilling(!automaticBilling)}
            label='Domiciliar el pago (se hará un cobro automatico cuando la factura del mes este lista)' />
          <br />
          <br />

          <button
            className="button-stripe"
            disabled={processing || disabled || succeeded}
            id="submit"
          >
            <span id="button-text">
              {processing ? (
                <div className="spinner" id="spinner"></div>
              ) : (
                'Pagar $ ' + total
              )}
            </span>
          </button>
        
          {/* Show any error that happens when processing the payment */}
          {error && (
            <div className="card-error" role="alert">
              {error}
            </div>
          )}
          
          {/* Show a success message upon completion */}
          <p className={succeeded ? 'result-message' : 'result-message hidden'}>
            Su pago se ha procesado correctamente 
            <a href={`https://dashboard.stripe.com/test/payments`}>
              {' '}
              Stripe dashboard.
            </a>{' '}
            
            <a href="#" onClick={() => {}}>Continuar</a>
          </p>
        </form>
      </Modal.Content>
    </Modal>
  );
};

export default React.memo(CheckoutForm);
