import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Button, Icon, Header } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import { Form, Fields } from '../../components';
import APIclient from '../../api';
import usePermissions from '../../hooks/usePermissions';

const ClientProfileLocation = ({
  state,
  props,
  history,
  clientProfileLocation
}) => {
  const [hasPermission] = usePermissions('/clientes2', history);
  const { id, index,locid } = useParams();
  const feathersService = APIclient.service('/api/clients');
  let clientData =state?.editclient.editclient??null;
  let editIndex = index ==='nuevo'?null:index;
  let editLocationID = locid==='0'? null:locid;
  let editValues =  locid? clientData?.locations[index]: null;

  useEffect(() => {
    if (clientData===null) {
      history.push(`/clientes2`);
    } });
  
  const handleSave = () => {
    if (editIndex == null) {
      clientData.locations.push(clientProfileLocation);
    } else {
      const newState = clientData.locations.map((obj) =>
        obj._id === editLocationID ? clientProfileLocation : obj
      );
      clientData.locations = [];
      clientData.locations = newState;
    }

    (async () => {
      feathersService
        .patch(clientData._id, clientData)
        .then((response) => {          
          props.history.goBack();
        });
    })();
  };

  return (
    <Container style={{ display: 'flex', flexDirection: 'column' }}>
      {hasPermission && 
        <>
          <Header as="h2" floated="left" className="header_page">
            {index === 'nuevo' ? 'Agregar domicilio' : 'Editar domicilio'}
          </Header>
          
          <Form form="clientProfileLocation" initialValues={editValues}>
            <Fields
              fieldsData={{
                name: {
                  name: 'name',
                  label: 'Nombre del domicilio ',
                  placeholder: 'Nombre del domicilio',
                  type: 'text',
                  required: false,
                  disabled: false,
                  columns: 7,
                },
                phone: {
                  name: 'phone',
                  label: 'Teléfono',
                  placeholder: 'Télefono',
                  type: 'text',
                  required: false,
                  disabled: false,
                  columns: 8,
                },
                street: {
                  name: 'street',
                  label: 'Calle',
                  placeholder: 'Calle',
                  type: 'text',
                  required: false,
                  disabled: false,
                  columns: 7,
                },
                number: {
                  name: 'number',
                  label: 'Número',
                  placeholder: 'Número',
                  type: 'text',
                  required: false,
                  disabled: false,
                  columns: 4,
                },
                internalNumber: {
                  name: 'internalNumber',
                  label: 'Número interior',
                  placeholder: 'Número interior',
                  type: 'text',
                  required: false,
                  disabled: false,
                  columns: 4,
                },
                suburb: {
                  name: 'suburb',
                  label: 'Colonia',
                  placeholder: 'Colonia',
                  type: 'text',
                  required: false,
                  disabled: false,
                  columns: 7,
                },
                zipCode: {
                  name: 'zipCode',
                  label: 'Código postal',
                  placeholder: 'Código postal',
                  type: 'text',
                  required: false,
                  disabled: false,
                  columns: 4,
                },
                city: {
                  name: 'city',
                  label: 'Ciudad',
                  placeholder: 'Ciudad',
                  type: 'text',
                  required: false,
                  disabled: false,
                  columns: 7,
                },
                state: {
                  name: 'state',
                  label: 'Estado',
                  placeholder: 'Estado',
                  type: 'text',
                  required: false,
                  disabled: false,
                  columns: 7,
                },
                references: {
                  name: 'references',
                  label: 'Referencias',
                  placeholder: 'Referencias',
                  type: 'textarea',
                  required: false,
                  disabled: false,
                  columns: 15,
                },
                additionalinfo: {
                  name: 'radditionalinfo',
                  label: 'Informacion adicional',
                  placeholder: 'información',
                  type: 'textarea',
                  required: false,
                  disabled: false,
                  columns: 15,
                },
              }}
            />
          <div className="buttons" style={{ padding: 30, paddingRight: 60, marginBottom: 100 }}>
            <Button type="button" color="red" onClick={handleSave} floated="right">
              <Icon name="save" className="detailIcon" />
              &nbsp;Guardar
            </Button>
            <Button type="button" onClick={props.history.goBack} floated="right">Cancelar</Button>
          </div>
        </Form>
        </>
      }
      {!hasPermission && <div>No disponible.</div>}
    </Container>
  );
};

const mapStateToProps = (state, props) => ({
  clientProfileLocation: state.form.clientProfileLocation?.values ?? null,
  editClient: state.editclient.editclient??null,
  props: props,
  state:state
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientProfileLocation);


