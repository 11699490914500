import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Icon } from 'semantic-ui-react';
import { Form, Fields } from '../../components';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import APIclient from '../../api';
import axios from 'axios';


const ClientProfileNewPaymentMethod = ({
  open: isOpen,
  onClose,
  props
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  let clientData = props.clientData;
  let userData = props.userData;
  let editPaymentID = props.editPaymentID;
  const feathersService = APIclient.service('/api/clients');
  //const stripe = require('stripe')('pk_test_51HRRxwHIN25NnKdUIL311nISRVqQTO7EVBWy7YfYduM5lzDyVakaNcD04uu3sgOi3pNEgjIjLjNwBSzKyC6JcjK900202hypUR');
  const stripe = useStripe();
  const elements = useElements();
  const qs = require('qs');

  useEffect(() => {
    if (!clientData.stripeUserID) {
      createStripeClient();
    }
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     const data = { paymentmethods: [] }

  //     const response = await feathersService
  //       .patch(clientData._id, data)
  //       .then((response) => {
  //         console.log({ response })
  //       });

  //   })();
  // }, []);


  const handleSave = async (event) => {
    setIsDisabled(true);
    event.preventDefault();
    createStripePaymentMethod();
    
  }

  const createStripeClient = () => {
    const url = 'https://api.reestock.com.mx/create-customer';
    const data = { 'name': userData.name, 'email': userData.email };
    const options = {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url,
    };
    (async () => {
      const stripeCustomer = await axios(options);
      clientData.stripeUserID = stripeCustomer.data.StripeId.id;
      const response = await feathersService
        .patch(clientData._id, clientData)
        .then((response) => {
        });
    })();
  }

  const createStripePaymentMethod = async (event) => {
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    cardElement.customer = clientData.stripeUserID;

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.log('[error]', error);
      alert(error.message)
      setIsDisabled(false);
    } else {
      (async () => {
        const url = 'https://api.reestock.com.mx/add-card-customer';
        const data = { 'card': paymentMethod.id, 'customer': clientData.stripeUserID };
        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          data: qs.stringify(data),
          url,
        };
        const stripeCustomer = await axios(options);
        if(!stripeCustomer.data.error) {
          handleSavePaymentMethodToClient(stripeCustomer.data.card)
        }
        else {
          alert('No se pudo guardar el método de pago, intente con otro.');
        }
      })();
    }
  }

  const handleSavePaymentMethodToClient = (card) => {
    if (!clientData.paymentmethods) {
      let payments = [];
      clientData.paymentmethods = payments;
    }

    clientData.paymentmethods.push(card);

    (async () => {
      const response = await feathersService
        .patch(clientData._id, clientData)
        .then((response) => {
          props.setEditorOpen(false);
        });

      setIsDisabled(false);
    })();
  };

  return (
    <Modal
      open={isOpen}
      closeIcon
      closeOnEscape={true}
      closeOnDimmerClick={true}
      onClose={() => onClose(false)}
    >
      <Modal.Header>
        {editPaymentID === '' ? 'Agregar metodo de pago' : 'Editar metodo de pago'}
      </Modal.Header>
      <Modal.Content>
        <CardElement
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                  color: '#aab7c4',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => onClose(false)}>Cancelar</Button>
        <Button color="red" onClick={handleSave} disabled={isDisabled}>
          <Icon name="save" className="detailIcon" />
          &nbsp;Guardar Tarjeta
        </Button>
        <br />
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps = (state, props) => ({
  props: props,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientProfileNewPaymentMethod);
