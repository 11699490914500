import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { validateTokenSignup } from '../../actions';

const Validate = ({ history, location, validateTokenSignup }) => {
  useEffect(() => {
    (async () => {
      const { pathname } = location;
      const token = pathname.split('/')[3];
      const isValidToken = await validateTokenSignup(token);

      isValidToken ? history.push('/recibos') : alert('Token no válido');
    })();
  }, []);

  return <div>validando...</div>;
};

const mapDispatchToProps = (dispatch) => ({
  validateTokenSignup: (token) => dispatch(validateTokenSignup(token)),
});

export default connect(null, mapDispatchToProps)(Validate);
