import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Table,Button,Icon,Divider } from 'semantic-ui-react';

//Services
import APIclient from '../../api';

const CSVChargedDetailList = (props) => {
  const feathersService = APIclient.service('/api/csvchargesupload');
  const [loadedCSV, setLoadedCSV] = useState([]);
  const paginate = 20;

  return (
    <div>
        <Button color="red" floated="right" onClick={() => {props.history.push('/csvupload') }}>
          <Icon name="arrow left" className="detailIcon" />
          &nbsp;Regresar
        </Button>
        <br/><br/>
        <Divider/>
      <Table basic="very" style={{ marginTop: 20 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>IoT ID</Table.HeaderCell>
            <Table.HeaderCell>Nombre</Table.HeaderCell>
            <Table.HeaderCell>Fecha Inicio</Table.HeaderCell>
            <Table.HeaderCell>Fecha Corte</Table.HeaderCell>
            <Table.HeaderCell>Total</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {props.location.state.detailValues.length > 0 ? (
            props.location.state.detailValues.map((theObject) => (
              <Table.Row key={theObject._id}>
                <Table.Cell
                  children={theObject.iotId}
                  collapsing={false}
                ></Table.Cell>
                <Table.Cell
                  children={theObject.name}
                  collapsing={false}
                ></Table.Cell>
                <Table.Cell
                  children={theObject.startDate}
                  collapsing={false}
                ></Table.Cell>
                <Table.Cell
                  children={theObject.outageDate}
                  collapsing={false}
                ></Table.Cell>
                <Table.Cell
                  children={theObject.total}
                  collapsing={false}
                ></Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell></Table.Cell>
              <Table.Cell>Sin información</Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  //My name ---------------------- Action Name
});

export default connect(
  null,
  mapDispatchToProps
)(withRouter(CSVChargedDetailList));
