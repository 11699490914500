import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Form } from 'semantic-ui-react';

const ElementsForm = ({ onSubmit, children }) => (
  <Form onSubmit={onSubmit}>{children}</Form>
);

const mapStateToProps = (_, { form, enableReinitialize, initialValues }) => ({
  form: form || 'leetName',
  enableReinitialize: enableReinitialize || false,
  initialValues,
});

const FormComponent = connect(mapStateToProps)(reduxForm({})(ElementsForm));

export default FormComponent;
