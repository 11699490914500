import React from 'react';
import { connect } from 'react-redux';
import { Card, Divider, Header, Container, Tab } from 'semantic-ui-react';
import { Form, Fields } from '../../components';
import { createClient } from '../../actions';

const ClientsForm = ({ clientsForm, createClient }) => {
  const handleSubmit = () => {
    if (clientsForm != null) {
      let clientToSave = {
        user: '5f78bce6aada5535e023d192',
        billingInformation: {
          type: clientsForm.type ?? '',
          name1: clientsForm.name1 ?? '',
          name2: clientsForm.name2 ?? '',
          lastName1: clientsForm.lastName1 ?? '',
          lastName2: clientsForm.LastName2 ?? '',
          phone: clientsForm.phone ?? '',
          street: clientsForm.street ?? '',
          internalNumber: clientsForm.internalNumber ?? '',
          suburb: clientsForm.suburb ?? '',
          zipCode: clientsForm.zipCode ?? '',
          city: clientsForm.city ?? '',
          state: clientsForm.state ?? '',
          references: clientsForm.references ?? '',
          additionalInfo: clientsForm.additionalInfo ?? '',
        },
        locations: [
          {
            name: clientsForm.locationname ?? '',
            street: clientsForm.locationstreet ?? '',
            number: clientsForm.locationnumber ?? '',
            internalNumber: clientsForm.locationinternalNumber ?? '',
            suburb: clientsForm.locationsuburb ?? '',
            zipCode: clientsForm.locationzipCode ?? '',
            city: clientsForm.locationcity ?? '',
            state: clientsForm.locationstate ?? '',
            references: clientsForm.locationreferences ?? '',
            additionalInfo: clientsForm.locationradditionalinfo ?? '',
          },
        ],
      };

      createClient(clientToSave);
    }
  };

  const panes = [
    {
      menuItem: 'Información de facturación',
      render: () => <Tab.Pane>{tabBillingInfo()}</Tab.Pane>,
    },
    {
      menuItem: 'Domicilios',
      render: () => <Tab.Pane>{tabLocations()}</Tab.Pane>,
    },
    //  { menuItem: 'Servicios contratados', render: () => <Tab.Pane>{tabServices()}</Tab.Pane> },
  ];

  const tabBillingInfo = () => {
    return (
      <Card fluid color="red">
        <Card.Content header="Información de facturación" />
        <Card.Content>
          {/* <Form form="formClientBillingInformation" onSubmit={handleSubmit}> */}
          <Fields
            fieldsData={{
              type: {
                name: 'type',
                label: 'Tipo de persona',
                placeholder: 'Seleccione el tipo de persona',
                type: 'select',
                required: false,
                disabled: false,
                options: [
                  { key: 'moral', value: 'moral', text: 'Moral' },
                  { key: 'fisica', value: 'fisica', text: 'Física' },
                ],
                columns: 5,
              },
              name1: {
                name: 'name1',
                label: 'Primer nombre',
                placeholder: 'Primer nombre',
                type: 'text',
                required: true,
                disabled: false,
                columns: 5,
              },
              name2: {
                name: 'name2',
                label: 'Segundo nombre',
                placeholder: 'Segundo nombre',
                type: 'text',
                required: false,
                disabled: false,
                columns: 5,
              },
              lastName1: {
                name: 'lastName1',
                label: 'Primer apellido',
                placeholder: 'Primer apellido',
                type: 'text',
                required: true,
                disabled: false,
                columns: 5,
              },
              lastName2: {
                name: 'lastName2',
                label: 'Segundo apellido',
                placeholder: 'Segundo apellido',
                type: 'text',
                required: false,
                disabled: false,
                columns: 5,
              },
              phone: {
                name: 'phone',
                label: 'Teléfono',
                placeholder: 'Télefono',
                type: 'text',
                required: false,
                disabled: false,
                columns: 5,
              },
              street: {
                name: 'street',
                label: 'Calle',
                placeholder: 'Calle',
                type: 'text',
                required: false,
                disabled: false,
                columns: 7,
              },
              number: {
                name: 'number',
                label: 'Número',
                placeholder: 'Número',
                type: 'text',
                required: false,
                disabled: false,
                columns: 4,
              },
              internalNumber: {
                name: 'internalNumber',
                label: 'Número interior',
                placeholder: 'Número interior',
                type: 'text',
                required: false,
                disabled: false,
                columns: 4,
              },
              suburb: {
                name: 'suburb',
                label: 'Colonia',
                placeholder: 'Colonia',
                type: 'text',
                required: false,
                disabled: false,
                columns: 7,
              },
              zipCode: {
                name: 'zipCode',
                label: 'Código postal',
                placeholder: 'Código postal',
                type: 'text',
                required: false,
                disabled: false,
                columns: 3,
              },
              city: {
                name: 'city',
                label: 'Ciudad',
                placeholder: 'Ciudad',
                type: 'text',
                required: false,
                disabled: false,
                columns: 7,
              },
              state: {
                name: 'state',
                label: 'Estado',
                placeholder: 'Estado',
                type: 'text',
                required: false,
                disabled: false,
                columns: 7,
              },
              references: {
                name: 'references',
                label: 'Referencias',
                placeholder: 'Referencias',
                type: 'textarea',
                required: false,
                disabled: false,
                columns: 15,
              },
              additionalInfo: {
                name: 'additionalInfo',
                label: 'Información adicional',
                placeholder: 'Información adicional',
                type: 'textarea',
                required: false,
                disabled: false,
                columns: 15,
              },
            }}
          />
          {/* </Form> */}
        </Card.Content>
      </Card>
    );
  };

  const tabLocations = () => {
    return (
      <Card fluid color="red">
        <Card.Content header="Domicilios" />
        <Card.Content>
          {/* <Form form="formClientLocations" onSubmit={handleSubmit}> */}
          <Fields
            fieldsData={{
              locationname: {
                name: 'locationname',
                label: 'Nombre del domicilio ',
                placeholder: 'Nombre del domicilio',
                type: 'text',
                required: false,
                disabled: false,
                columns: 7,
              },
              locationphone: {
                name: 'locationphone',
                label: 'Teléfono',
                placeholder: 'Télefono',
                type: 'text',
                required: false,
                disabled: false,
                columns: 8,
              },
              locationstreet: {
                name: 'locationstreet',
                label: 'Calle',
                placeholder: 'Calle',
                type: 'text',
                required: false,
                disabled: false,
                columns: 7,
              },
              locationnumber: {
                name: 'locationnumber',
                label: 'Número',
                placeholder: 'Número',
                type: 'text',
                required: false,
                disabled: false,
                columns: 4,
              },
              locationinternalNumber: {
                name: 'locationinternalNumber',
                label: 'Número interior',
                placeholder: 'Número interior',
                type: 'text',
                required: false,
                disabled: false,
                columns: 4,
              },
              locationsuburb: {
                name: 'locationsuburb',
                label: 'Colonia',
                placeholder: 'Colonia',
                type: 'text',
                required: false,
                disabled: false,
                columns: 7,
              },
              locationzipCode: {
                name: 'locationzipCode',
                label: 'Código postal',
                placeholder: 'Código postal',
                type: 'text',
                required: false,
                disabled: false,
                columns: 4,
              },
              locationcity: {
                name: 'locationcity',
                label: 'Ciudad',
                placeholder: 'Ciudad',
                type: 'text',
                required: false,
                disabled: false,
                columns: 7,
              },
              locationstate: {
                name: 'locationstate',
                label: 'Estado',
                placeholder: 'Estado',
                type: 'text',
                required: false,
                disabled: false,
                columns: 7,
              },
              locationreferences: {
                name: 'locationreferences',
                label: 'Referencias',
                placeholder: 'Referencias',
                type: 'textarea',
                required: false,
                disabled: false,
                columns: 15,
              },
              locationradditionalinfo: {
                name: 'locationradditionalinfo',
                label: 'Informacion adicional',
                placeholder: 'información',
                type: 'textarea',
                required: false,
                disabled: false,
                columns: 15,
              },
            }}
          />
          {/* </Form> */}
        </Card.Content>
      </Card>
    );
  };

  const tabServices = () => {
    return (
      <Card fluid color="red">
        <Card.Content header="Servicios contratados por domicilio" />
        <Card.Content>
          <Form form="formClientLocationsServices" onSubmit={handleSubmit}>
            <Fields
              fieldsData={{
                type: {
                  name: 'type',
                  label: 'Tipo',
                  placeholder: 'Seleccione el tipo de servicio',
                  type: 'select',
                  required: false,
                  disabled: false,
                },
                iotID: {
                  name: 'iotID',
                  label: 'Id del dispositivo IoT',
                  placeholder: 'Id del dispositivo IoT',
                  type: 'text',
                  required: false,
                  disabled: false,
                },
                capacity: {
                  name: 'capacity',
                  label: 'Capacidad',
                  placeholder: 'Capacidad',
                  type: 'text',
                  required: false,
                  disabled: false,
                },
                contractStart: {
                  name: 'contractStart',
                  label: 'Fecha de inicio de contrato',
                  placeholder: '',
                  type: 'text',
                  required: false,
                  disabled: false,
                },
                contractEnd: {
                  name: 'contractEnd',
                  label: 'Fecha de fin de contrato',
                  placeholder: '',
                  type: 'text',
                  required: false,
                  disabled: false,
                },
                status: {
                  name: 'status',
                  label: 'Estatus',
                  placeholder: 'Seleccione el estatus',
                  type: 'select',
                  required: false,
                  disabled: false,
                },
                comments: {
                  name: 'comments',
                  label: 'Comentarios',
                  placeholder: 'Comentarios',
                  type: 'textarea',
                  required: false,
                  disabled: false,
                },
                overduedPayments: {
                  name: 'overduedPayments',
                  label: 'Pagos atrasados',
                  placeholder: 'Número de pagos atrasados',
                  type: 'text',
                  required: false,
                  disabled: false,
                },
              }}
            />
          </Form>
        </Card.Content>
      </Card>
    );
  };

  return (
    <div>
      <Container>
        <Header as="h1">Clientes</Header>

        <Card fluid color="red">
          <Card.Content header="Seleccionar usuario Reestock" />
          <Card.Content>
            {/* <Form form="formClients" onSubmit={handleSubmit}>
              <Fields
                fieldsData={{
                  user: {
                    name: 'user',
                    label: 'Usuario Reestock',
                    placeholder: 'Seleccione el usuario',
                    type: 'select',
                    required: false,
                    disabled: false
                  }
                }}
              />
            </Form> */}
          </Card.Content>
        </Card>
        <Divider />

        <Form form="clientsForm" onSubmit={handleSubmit}>
          <button type="submit">submit</button>
          <Tab panes={panes} />

          <Divider />
        </Form>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  clientsForm: state.form.clientsForm?.values ?? null,
  editClientID: state.editClientID ?? null,
});

const mapDispatchToProps = (dispatch) => ({
  createClient: (data) => dispatch(createClient(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientsForm);
