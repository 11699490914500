import React, { Component } from 'react';
import App from './pages';
import Clients from './pages/clients';

import Clients2 from './pages/clientsNew';
import Clients2Edit from './pages/clientsNew/Edit';
import FormClientAddress from './pages/clientsNew/FormAddress';
import FormBilling from './pages/clientsNew/FormBilling';
import FormServices from './pages/clientsNew/FormServices';


import FormClients from './pages/clients/form';
import ClientsEditForm from './pages/clients/edit';
import ClientProfile from './pages/clientprofile';
import ClientProfileLocations from './pages/clientprofile/locations';
import ClientProfileBillingInfoList from './pages/clientprofile/billinginfo';
import ClientProfileServiceList from './pages/clientprofile/services';
import ClientProfilePaymentMethods from './pages/clientprofile/paymentmethods'
import Tickets from './pages/tickets';
import Users from './pages/users';
import FormUsers from './pages/users/form';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import CSVUpload from './pages/csvupload';
import CSVChargedDetailList from './pages/csvupload/details';
import Signup from './pages/signup';
import Login from './pages/login';
import Validate from './pages/users/Validate';

class RoutesApp extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <App>
            <Route path="/" exact component={Clients2} />

            <Route path="/clientes2" exact component={Clients2} />
            <Route path="/clientes2/editar/:id" exact component={Clients2Edit} />
            <Route path="/clientes2/editar/:id/:index/:locid" exact component={FormClientAddress} />
            <Route path="/clientes2/editarbi/:id/:index/:locid" exact component={FormBilling} />
            <Route path="/clientes2/editarsvc/:id/:index/:locid" exact component={FormServices} />

            <Route path="/clientes" exact component={Clients2} />
            <Route path="/clientes/new" exact component={FormClients} />
            <Route path="/clientes/edit" exact component={ClientsEditForm} />


            <Route path="/recibos" exact component={Tickets} />
            <Route path="/usuarios" exact component={Users} />
            <Route path="/usuarios/nuevo" exact component={FormUsers} />
            <Route path="/usuarios/editar" exact component={FormUsers} />
            <Route path="/perfil" exact component={ClientProfile} />
            <Route path="/perfil/domicilios" exact component={ClientProfileLocations} />
            <Route path="/perfil/facturacion" exact component={ClientProfileBillingInfoList} />
            <Route path="/perfil/servicios" exact component={ClientProfileServiceList} />
            <Route path="/perfil/metodospago" exact component={ClientProfilePaymentMethods} />
            <Route path="/csvupload" exact component={CSVUpload} />
            <Route
              path="/csvuploaddetail"
              exact
              component={CSVChargedDetailList}
            />
            <Route path="/registro" exact component={Signup} />
            <Route path="/login" exact component={Login} />
            <Route path="/validar/email/:token" exact component={Validate} />
          </App>
        </Switch>
      </BrowserRouter>
    );
  }
}

export default RoutesApp;
