import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Button, Icon, Header } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import { Form, Fields } from '../../components';
import APIclient from '../../api';
import usePermissions from '../../hooks/usePermissions';


const ClientProfileService = ({
    state,
    props,
    history,
    clientProfileService }) => {
    const [hasPermission] = usePermissions('/clientes2', history);
    const [clientServices, setClientServices] = useState();
    const { id, index, locid } = useParams();
    const feathersService = APIclient.service('/api/services');
    let clientData = state?.editclient.editclient ?? null;
    let editIndex = index === 'nuevo' ? null : index;
    let editID = locid === '0' ? null : locid;

    useEffect(() => {
        if (clientData === null) {
            history.push(`/clientes2`);
        } else {
            loadServices()
        }
    }, []);

    const loadServices = async (activePage = 0) => {
        const services = await feathersService
            .find({
                query: {
                    client: clientData._id,
                    _id: editID,
                    $limit: 20,
                    $skip: activePage * 20,
                },
            }).then((services) => {
                setClientServices(services.data[0])
            }
            )
    }

    const fieldsNewService = {
        locationid: {
            name: 'locationid',
            label: 'Domicilio',
            placeholder: 'Seleccione el domicilio',
            type: 'select',
            options: clientData ? parseLocations(clientData.locations) : [],
            required: false,
            disabled: false,
            columns: 8,
        },
        paymentMethod: {
            name: 'paymentMethod',
            label: 'Método de pago',
            placeholder: 'Seleccione el método de pago',
            type: 'select',
            options: clientData ? parsePaymentMethods(clientData.paymentmethods) : [],
            required: false,
            disabled: false,
            columns: 8,
        },
        billinginfoid: {
            name: 'billinginfoid',
            label: 'Información de facturación',
            placeholder: 'Seleccione la información de facturación',
            type: 'select',
            options: clientData ? parseBI(clientData.billingInformation) : [],
            required: false,
            disabled: false,
            columns: 8,
        },
        type: {
            name: 'type',
            label: 'Tipo de servicio',
            placeholder: 'Seleccione la tipo de servicio',
            type: 'select',
            options: [{ key: 'Gas', value: 'Gas', text: 'Gas' }],
            required: false,
            disabled: false,
            columns: 8,
        },
        iotID: {
            name: 'iotID',
            label: 'IoT ID',
            placeholder: 'Iot ID',
            type: 'text',
            required: false,
            disabled: false,
            columns: 8,
        },
        capacity: {
            name: 'capacity',
            label: 'Capacidad del servicio',
            placeholder: 'Capacidad del sertvicio',
            type: 'text',
            required: false,
            disabled: false,
            columns: 4,
        },
        contractStart: {
            name: 'contractStart',
            label: 'Inicio del contrato',
            placeholder: 'Fecha de inicio del contrato',
            type: 'calendar',
            required: false,
            disabled: false,
            columns: 8,
        },
        comments: {
            name: 'comments',
            label: 'Descripción',
            placeholder: 'Comentarios',
            type: 'text',
            required: false,
            disabled: false,
            columns: 16,
        },

        automaticbilling: {
            name: 'automaticbilling',
            label: 'Pago domiciliado',
            placeholder: 'Pago domiciliado',
            type: 'checkbox',
            required: false,
            disabled: false
        }
    }

    const fieldsEditService = {
        locationid: {
            name: 'locationid',
            label: 'Domicilio',
            placeholder: 'Seleccione el domicilio',
            type: 'select',
            options: clientData ? parseLocations(clientData.locations) : [],
            required: false,
            disabled: false,
            columns: 8,
        },
        paymentMethod: {
            name: 'paymentMethod',
            label: 'Método de pago',
            placeholder: 'Seleccione el método de pago',
            type: 'select',
            options: clientData ? parsePaymentMethods(clientData.paymentmethods) : [],
            required: false,
            disabled: false,
            columns: 8,
        },
        billinginfoid: {
            name: 'billinginfoid',
            label: 'Información de facturación',
            placeholder: 'Seleccione la información de facturación',
            type: 'select',
            options: clientData ? parseBI(clientData.billingInformation) : [],
            required: false,
            disabled: false,
            columns: 8,
        },
        type: {
            name: 'type',
            label: 'Tipo de servicio',
            placeholder: 'Seleccione la tipo de servicio',
            type: 'select',
            options: [{ key: 'Gas', value: 'Gas', text: 'Gas' }],
            required: false,
            disabled: false,
            columns: 8,
        },
        iotID: {
            name: 'iotID',
            label: 'IoT ID',
            placeholder: 'Iot ID',
            type: 'text',
            required: false,
            disabled: false,
            columns: 8,
        },
        capacity: {
            name: 'capacity',
            label: 'Capacidad del servicio',
            placeholder: 'Capacidad del sertvicio',
            type: 'text',
            required: false,
            disabled: false,
            columns: 4,
        },
        contractStart: {
            name: 'contractStart',
            label: 'Inicio del contrato',
            placeholder: 'Fecha de inicio del contrato',
            type: 'calendar',
            required: false,
            disabled: false,
            columns: 8,
        },
        contractEnd: {
            name: 'contractEnd',
            label: 'Fin del contrato',
            placeholder: 'Fecha de fin de contrato',
            type: 'calendar',
            required: false,
            disabled: false,
            hidden: true,
            columns: 8,
        },
        comments: {
            name: 'comments',
            label: 'Descripción',
            placeholder: 'Comentarios',
            type: 'text',
            required: false,
            disabled: false,
            columns: 16,
        },

        automaticbilling: {
            name: 'automaticbilling',
            label: 'Pago domiciliado',
            placeholder: 'Pago domiciliado',
            type: 'checkbox',
            required: false,
            disabled: false
        }
    }

    const handleSave = () => {
        let serviceToSave = {
            client: clientData._id,
            locationid: clientProfileService.locationid,
            billinginfoid: clientProfileService.billinginfoid,
            type: clientProfileService.type,
            iotID: clientProfileService.iotID,
            paymentMethod: clientProfileService.paymentMethod,
            capacity: clientProfileService.capacity,
            contractStart: clientProfileService.contractStart,
            contractEnd: clientProfileService.contractEnd,
            status: clientProfileService.status,
            comments: clientProfileService.comments,
            overduedPayments: 0, // clientProfileService.overduedPayments,
            type: "Gas",
            stripeUserID: clientData.stripeUserID ?? "",
            automaticbilling: clientProfileService.automaticbilling ?? false
        };

        if (clientServices == null) {
            (async () => {
                feathersService
                    .create({ ...serviceToSave, serviceReadings: [] })
                    .then((response) => {
                        props.history.goBack();
                    });
            })();
        } else {
            (async () => {
                feathersService
                    .patch(editID, serviceToSave)
                    .then((response) => {
                        props.history.goBack();
                    });
            })();
        }
    };

    function parseLocations(locations) {
        return locations.map(({ name, street, number, suburb, _id }) => {
            return {
                key: _id,
                text: `${name} ${street} ${number} ${suburb}`,
                value: _id,
            };
        });
    }

    function parseBI(BIs) {
        return BIs.map((BIs) => {
            return { key: BIs._id, text: BIs.rfc, value: BIs._id };
        });
    }

    function parsePaymentMethods(PMs) {
        return PMs.map((PM) => {
            return { key: PM.id, text: PM.card.brand + ' ' + PM.card.last4, value: PM.id };
        });
    }


    return (
        <Container style={{ display: 'flex', flexDirection: 'column' }}>
            {hasPermission &&
                <>
                    <Header as="h2" floated="left" className="header_page">
                        {index === 'nuevo' ? 'Agregar servicio' : 'Editar servicio'}
                    </Header>
                    <Form form="clientProfileService" initialValues={clientServices}>
                        <Fields
                            fieldsData={editIndex === -1 ? fieldsNewService : fieldsEditService}
                        />
                        <div className="buttons" style={{ padding: 30, paddingRight: 60, marginBottom: 100 }}>
                            <Button type="button" color="red" onClick={handleSave} floated="right">
                                <Icon name="save" className="detailIcon" />
                                &nbsp;Guardar
                            </Button>
                            <Button type="button" onClick={props.history.goBack} floated="right">Cancelar</Button>
                        </div>
                    </Form>
                </>
            } {!hasPermission && <div>No disponible.</div>}
        </Container>
    );
};

const mapStateToProps = (state, props) => ({
    clientProfileService: state.form.clientProfileService?.values ?? null,
    editClient: state.editclient.editclient ?? null,
    props: props,
    state: state
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(ClientProfileService);

