import React, { useState, useEffect, createRef } from 'react';
import {
  Button,
  Table,
  Divider,
  Modal,
  Icon,
  Header,
  Container,
} from 'semantic-ui-react';
import * as XLSX from 'xlsx';
import APIclient from '../../api';
import CSVChargedList from './list';

const CSVUpload = (props) => {
  const feathersService = APIclient.service('/api/csvchargesupload');
  const fileInputRef = createRef();

  const newSave = {
    chargeDate: new Date().toLocaleDateString(),
  };

  const [gridData, setGridData] = useState([]);
  const [uploadHeaders, setUploadHeaders] = useState([]);
  const [newSaveComplete, setNewSave] = useState(newSave);
  const [editorOpen, setEditorOpen] = useState(false);
  const [readedData, setReadedData] = useState([]);
  const [loadedCSV, setLoadedCSV] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const paginate = 10;

  useEffect(() => {
    loadCSVData(paginate, activePage);
  }, []);

  const keys = [
    'index',
    'name',
    'iotId',
    'startDate',
    'outageDate',
    'days',
    'capacity',
    'initialPercentage',
    'initialAmount',
    'readingPercentage1',
    'readingAmount1',
    'readingConsumption1',
    'chargesCount',
    'periodTotalCharge',
    'periodTotalConsumption',
    'dayAverageConsuption',
    'remainingDaysConsuptionBase',
    'unitCost',
    'periodTotal',
    'overduedTotal1',
    'overduedTotal2',
    'overduedTotal3',
    'total'
  ];

  const handleUpload = (evt) => {
    let loadedInfoJSON = [];
    const inputTarget = evt.target;
    let csvReader = new FileReader();

    csvReader.readAsArrayBuffer(inputTarget.files[0]);
    csvReader.onloadend = (e) => {
      let readData = new Uint8Array(e.target.result);
      var fileLoaded = XLSX.read(readData, { type: 'array' });
      fileLoaded.SheetNames.forEach(function (sheetName) {
        var fileRowObject = XLSX.utils.sheet_to_row_object_array(
          fileLoaded.Sheets[sheetName]
        );
        var fileRowObject = XLSX.utils.sheet_to_row_object_array(
          fileLoaded.Sheets[sheetName],{skipHeader: 1}
        );
        loadedInfoJSON.push({
          data: fileRowObject,
          sheetName,
        });

        handleJsonFromFile(loadedInfoJSON[0]);
      });
    };
  };

  const handleJsonFromFile = (data) => {
    if (data.data.length != 0) {
      var resultHeader = new Array();
      var resultData = new Array();
      var finalData = new Array();

      for (const row in data.data) {
        for (const item in data.data[row]) {
          if (row == 0) {
            resultHeader.push(data.data[row][item]);
          } else {
            resultData.push(data.data[row][item]);
          }
        }
      }

      data.data.map((result) => {
        finalData.push(result);
      });
      setUploadHeaders(resultHeader);
      //finalData.splice(0, 1);
      setGridData(finalData);
      setReadedData(finalData);
    } else {
      alert('Archivo no valido');
    }
  };

  const handleSave = () => {
    let algo = readedData.map((data) => {
      const values = Object.values(data);
      const myObj = Object.fromEntries(
        keys.map((key, index) => [key, values[index]])
      );
      return myObj;
    });

    let toSave = { ...newSaveComplete };
    toSave.values = algo;

    setNewSave(toSave);
    (async () => {
      const response = await feathersService.create(toSave).then((response) => {
        setUploadHeaders([]);
        setGridData([]);
        setEditorOpen(false);
        loadCSVData(paginate, activePage);
      });
    })();
  };

  const handleShowModal = () => {
    setGridData([]);
    setUploadHeaders([]);
    setEditorOpen(true);
  };

  const loadCSVData = (paginate, activePage) => {
    (async () => {
      const loadCSV = await feathersService.find({
        query: { $limit: paginate, $skip: activePage * paginate,$sort:{createdAt:-1} },
      });

      if ((loadCSV !== undefined && loadCSV.data.length !== 0)) {
        const _totalPages = parseInt(loadCSV.total / paginate)
        const _rest = _totalPages % paginate > 0 ? 1 : 0
        setTotalPages(_totalPages + _rest)
      }
      setLoadedCSV(loadCSV.data);
    })();
  };

  const handlePaginationChange = (e, { activePage }) => {
    setActivePage(activePage)
    loadCSVData(paginate, activePage - 1)
  }

  return (
    <Container>
      <Header as="h2" floated="left" className="header_page">
        Documentos cargados recientemente
      </Header>

      <Button color="red" floated="right" onClick={handleShowModal}>
        <Icon name="add" className="detailIcon" />
        &nbsp;Cargar documento
      </Button>

      <CSVChargedList
        loadedCSV={loadedCSV}
        activePage={activePage === 0 ? activePage + 1 : activePage}
        onPageChange={handlePaginationChange}
        totalPages={totalPages}
      />

      <Modal
        open={editorOpen}
        closeIcon
        closeOnEscape={true}
        closeOnDimmerClick={true}
        onClose={() => setEditorOpen(false)}
      >
        <Modal.Header>Cargar</Modal.Header>
        <Modal.Content>
          <div className="field">
            <Button
              icon="upload"
              label={{
                basic: true,
                pointing: 'left',
                content: 'Seleccionar un archivo',
              }}
              onClick={(e) => {
                e.preventDefault();
                fileInputRef.current.click();
              }}
            />
            <input
              id="file"
              ref={fileInputRef}
              type="file"
              hidden
              onChange={handleUpload}
            />
            {/* {files && <span>{files.name}</span>} */}
          </div>
          <Divider />
          <Container>
            <div style={{ maxWidth: 900, overflowY: 'scroll' }}>
              <Table color="red" basic="very" style={{ marginTop: 20 }}>
                <Table.Header>
                  <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell>Nombre</Table.HeaderCell>
                  <Table.HeaderCell>Embeded ID</Table.HeaderCell>
                  <Table.HeaderCell>Fecha de inicio</Table.HeaderCell>
                  <Table.HeaderCell>Fecha de corte</Table.HeaderCell>
                  <Table.HeaderCell>Días Transcurridos</Table.HeaderCell>
                  <Table.HeaderCell>Capacidad LTS</Table.HeaderCell>
                  <Table.HeaderCell>Lectura Inicial Periodo(%)</Table.HeaderCell>
                  <Table.HeaderCell>Lectura Inicial Periodo(LTS)</Table.HeaderCell>
                  <Table.HeaderCell>Primera Lectura Periodo(%)</Table.HeaderCell>
                  <Table.HeaderCell>Primera Lectura Periodo(LTS)</Table.HeaderCell>
                  <Table.HeaderCell>Consumo Realizado</Table.HeaderCell>
                  <Table.HeaderCell>Número de cargas</Table.HeaderCell>
                  <Table.HeaderCell>Carga Total Periodo(LTS)</Table.HeaderCell>
                  <Table.HeaderCell>Consumo Total Periodo(LTS)</Table.HeaderCell>
                  <Table.HeaderCell>Consumo Promedio Periodo(LTS)</Table.HeaderCell>
                  <Table.HeaderCell>Días Restantes Con Servicio</Table.HeaderCell>
                  <Table.HeaderCell>Precio Unitario</Table.HeaderCell>
                  <Table.HeaderCell>Total Periodo Actual</Table.HeaderCell>
                  <Table.HeaderCell>Pago Vencido 1</Table.HeaderCell>
                  <Table.HeaderCell>Pago Vencido 2</Table.HeaderCell>
                  <Table.HeaderCell>Pago Vencido 3</Table.HeaderCell>
                  <Table.HeaderCell>Gran Total</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {gridData.map((item) => (
                    <tr key={item.id}>
                      {Object.values(item).map((val) => (
                        <td>{val}</td>
                      ))}
                    </tr>
                  ))}
                </Table.Body>
              </Table>
            </div>
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={() => handleSave()}>
            <Icon name="checkmark" />
            Guardar
          </Button>
        </Modal.Actions>
      </Modal>
    </Container>
  );
};

export default CSVUpload;
