import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Divider,
  Header,
  Container,
  Tab,
  Icon,
  Table,
  Button,
  Modal,
} from 'semantic-ui-react';
import { createClient } from '../../actions';
import ClientProfileLocation from '../clientprofile/newlocation';
import ClientProfileBillingInfo from '../clientprofile/newbillinginfo';
import ClientServices from './newservices';
import usePermissions from '../../hooks/usePermissions';
import APIclient from '../../api';

const ClientsEditForm = ({ state, history }) => {
  const servicesService = APIclient.service('/api/services');
  const [hasPermission] = usePermissions(window.location.pathname, history);
  const [editorOpen, setEditorOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const [editID, setEditID] = useState('');
  const [componentOnModal, setComponentOnModal] = useState('');
  const [clientServices, setClientServices] = useState([]);
  const paginate = 20;

  const panes = [
    {
      menuItem: 'Domicilios',
      render: () => <Tab.Pane>{tabLocations()}</Tab.Pane>,
    },
    {
      menuItem: 'Información de facturación',
      render: () => <Tab.Pane>{tabBillingInfo()}</Tab.Pane>,
    },
    {
      menuItem: 'Servicios contratados',
      render: () => <Tab.Pane>{tabServices()}</Tab.Pane>,
    },
    {
      menuItem: 'Métodos de pago',
      render: () => <Tab.Pane>{tabPaymentMethods()}</Tab.Pane>,
    }
  ];

  const tabLocations = () => {
    return (
      <div>
        <Header as="h2">
          Domicilios
          <Button color="red" floated="right" onClick={handleNewLocation}>
            <Icon name="add" className="detailIcon" />
            &nbsp;Agregar Domicilio
          </Button>
        </Header>
        <Divider />
        <Table singleLine striped style={{ marginTop: 20 }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Nombre</Table.HeaderCell>
              <Table.HeaderCell>Ciudad</Table.HeaderCell>
              <Table.HeaderCell>Estado</Table.HeaderCell>
              <Table.HeaderCell collapsing={true}>Acciones</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {state.editclient.editclient.locations &&
              state.editclient.editclient.locations.length != 0 ? (
                state.editclient.editclient.locations.map((location, index) => (
                  <Table.Row key={location._id}>
                    <Table.Cell
                      children={location.name}
                      collapsing={false}
                    ></Table.Cell>
                    <Table.Cell
                      children={location.city}
                      collapsing={false}
                    ></Table.Cell>
                    <Table.Cell
                      children={location.state}
                      collapsing={false}
                    ></Table.Cell>
                    <Table.Cell>
                      <Button
                        circular
                        icon="pencil"
                        size="mini"
                        color="green"
                        data={location._id}
                        onClick={() => handleEditLocation(index, location._id)}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell></Table.Cell>
                  <Table.Cell>Sin información</Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
              )}
          </Table.Body>
        </Table>
        <Divider />
      </div>
    );
  };

  const tabBillingInfo = () => {
    return (
      <div>
        <Header as="h2">
          Información de facturación
          <Button color="red" floated="right" onClick={handleNewBI}>
            <Icon name="add" className="detailIcon" />
            &nbsp;Agregar información de facturación
          </Button>
        </Header>
        <Divider />
        <Table singleLine striped style={{ marginTop: 20 }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>RFC</Table.HeaderCell>
              <Table.HeaderCell>Nombre</Table.HeaderCell>
              <Table.HeaderCell collapsing={true}>Acciones</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {state.editclient.editclient.billingInformation &&
              state.editclient.editclient.billingInformation.length != 0 ? (
                state.editclient.editclient.billingInformation.map(
                  (BI, index) => (
                    <Table.Row key={BI._id}>
                      <Table.Cell
                        children={BI.rfc}
                        collapsing={false}
                      ></Table.Cell>
                      <Table.Cell
                        children={`${BI.name1} ${BI.name2 || ''} ${BI.lastName1} ${BI.lastName2 || ''}`}
                        collapsing={false}
                      ></Table.Cell>
                      <Table.Cell>
                        <Button
                          circular
                          icon="pencil"
                          size="mini"
                          color="green"
                          data={BI._id}
                          onClick={() => handleEditBI(index, BI._id)}
                        />
                      </Table.Cell>
                    </Table.Row>
                  )
                )
              ) : (
                <Table.Row>
                  <Table.Cell></Table.Cell>
                  <Table.Cell>Sin información</Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
              )}
          </Table.Body>
        </Table>
      </div>
    );
  };

  const tabServices = () => {
    return (
      <div>
        <Header as="h2">
          Servicios Contratados
          <Button color="red" floated="right" onClick={handleNewService}>
            <Icon name="add" className="detailIcon" />
            &nbsp;Agregar Servicio
          </Button>
        </Header>
        <Divider />
        <Table singleLine striped style={{ marginTop: 20 }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Tipo</Table.HeaderCell>
              <Table.HeaderCell>IoT</Table.HeaderCell>
              <Table.HeaderCell collapsing={true}>Acciones</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {clientServices.length != 0 ? (
              clientServices.map((Svc, index) => (
                <Table.Row key={Svc._id}>
                  <Table.Cell
                    children={Svc.type}
                    collapsing={false}
                  ></Table.Cell>
                  <Table.Cell
                    children={Svc.iotID}
                    collapsing={false}
                  ></Table.Cell>
                  <Table.Cell>
                    <Button
                      circular
                      icon="pencil"
                      size="mini"
                      color="green"
                      data={Svc._id}
                      onClick={() => handleEditService(index, Svc._id)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
                <Table.Row>
                  <Table.Cell></Table.Cell>
                  <Table.Cell>Sin información</Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
              )}
          </Table.Body>
        </Table>
      </div>
    );
  };

  const tabPaymentMethods = () => {
    return (
      <div>
        <Header as="h2">
          Métodos de pago
        </Header>
        <Divider />
        <Table singleLine striped style={{ marginTop: 20 }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Descripcion</Table.HeaderCell>
              <Table.HeaderCell>Últimos 4 dígitos</Table.HeaderCell>
              <Table.HeaderCell>Valida hasta</Table.HeaderCell>
              <Table.HeaderCell collapsing={true}>Acciones</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {state.editclient.editclient.paymentmethods &&
              state.editclient.editclient.paymentmethods.length != 0 ? (
                state.editclient.editclient.paymentmethods.map(
                  (payment, index) => (
                    <Table.Row key={payment._id}>
                      <Table.Cell
                        children={payment.card.brand}
                        collapsing={false}
                      ></Table.Cell>
                      <Table.Cell
                        children={payment.card.last4}
                        collapsing={false}
                      ></Table.Cell>
                      <Table.Cell
                        children={payment.card.exp_month + '/' + payment.card.exp_year}
                        collapsing={false}
                      ></Table.Cell>
                      <Table.Cell>
                        <Button
                          circular
                          icon="pencil"
                          size="mini"
                          color="green"
                          data={payment._id}
                        //onClick={() => handleEditLocation(index, payment._id)}
                        />
                      </Table.Cell>
                    </Table.Row>
                  )
                )
              ) : (
                <Table.Row>
                  <Table.Cell></Table.Cell>
                  <Table.Cell>Sin información</Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
              )}
          </Table.Body>
        </Table>

      </div>
    );
  };

  useEffect(() => {
    if (state.editclient.editclient) {
      loadClientsServices();
    } else {
      history.push('/clientes');
    }
  }, []);

  const loadClientsServices = (activePage = 0) => {
    (async () => {
      const loadClientServices = await servicesService
        .find({
          query: {
            client: state.editclient.editclient._id,
            $limit: paginate,
            $skip: activePage * paginate,
          },
        })
        .then((loadClientServices) => {
          setClientServices(loadClientServices.data);
        });
    })();
  };

  const handleEditLocation = (index, id) => {
    setComponentOnModal('location');
    setEditIndex(index);
    setEditID(id);
    setEditorOpen(true);
  };

  const handleNewLocation = () => {
    setComponentOnModal('location');
    setEditIndex(-1);
    setEditorOpen(true);
  };

  const handleEditBI = (index, id) => {
    setComponentOnModal('BI');
    setEditIndex(index);
    setEditID(id);
    setEditorOpen(true);
  };

  const handleNewBI = () => {
    setComponentOnModal('BI');
    setEditIndex(-1);
    setEditorOpen(true);
  };

  const handleEditService = (index, id) => {
    setComponentOnModal('SVC');
    setEditIndex(index);
    setEditID(id);
    setEditorOpen(true);
  };

  const handleNewService = () => {
    setComponentOnModal('SVC');
    setEditIndex(-1);
    setEditorOpen(true);
  };
  
  return (
    <div>
      {hasPermission && state.editclient.editclient && (
        <Container>
          <Header as="h2">
            Editar información del cliente:{' '}
            {state.editclient.editclient.user.name}
              <Button color="red" floated="right" onClick={() => { history.push('/clientes') }}>
                <Icon name="arrow left" className="detailIcon" />
                &nbsp;Regresar
              </Button>
          </Header>
        
        <Divider />
          <Tab panes={panes} />
          {editorOpen && componentOnModal === 'location' && (
            <ClientProfileLocation
              open={editorOpen}
              onClose={setEditorOpen}
              clientData={state.editclient.editclient}
              editIndex={editIndex}
              editLocationID={editID}
              setEditorOpen={setEditorOpen}
            />
          )}
          {editorOpen && componentOnModal === 'BI' && (
            <ClientProfileBillingInfo
              open={editorOpen}
              onClose={setEditorOpen}
              clientData={state.editclient.editclient}
              editIndex={editIndex}
              editLocationID={editID}
              setEditorOpen={setEditorOpen}
            />
          )}
          {editorOpen && componentOnModal === 'SVC' && (
            <ClientServices
              open={editorOpen}
              onClose={setEditorOpen}
              clientData={state.editclient.editclient}
              clientServices={clientServices}
              editIndex={editIndex}
              editID={editID}
              setEditorOpen={setEditorOpen}
              loadClientsServices={loadClientsServices}
            />
          )}
          <br />
        </Container>
      )}
      {!hasPermission && <div>No disponible.</div>}
    </div>
  );
};

const mapStateToProps = (state) => ({
  state: state,
  editClient: state.editclient.editclient ?? null,
});

const mapDispatchToProps = (dispatch) => ({
  createClient: (data) => dispatch(createClient(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientsEditForm);
