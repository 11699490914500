import APIClient from '../api';
const clientService = APIClient.service('/api/clients');
const services = APIClient.service('/api/services');
const paginate = 20;

export const createClient = (data) => async (dispatch) => {
  const response = await clientService.create(data);
  return response;
};

export const udpateClient = (id, data) => async (dispatch) => {
  const response = await clientService.patch(id, data);
  return response;
};

export const loadClients = (activePage , pendings = false, paginate) => async () => {
  const params = pendings ? { isValidated: false } : { isValidated: true };

  try {
    const loadClients = await clientService.find({
      query: {
        ...params,
        $populate: ['user'],
        $limit: paginate??null,
        $skip: paginate? (activePage * paginate):null,
      },
    });
    return loadClients;
  } catch (error) {
    console.log({ error });
    return [];
  }
};

export const verifyClient = (id) => async () => {
  try {
    const response = await clientService.patch(id, { isValidated: true });

    return response ? true : false;
  } catch (error) {
    console.log({ error });
    return false;
  }
};

export const loadClient = async (userId) => {
  try {
    const loadClients = await clientService.find({
      query: { user: userId },
    });

    return loadClients.data[0];
  } catch (error) {
    console.log({ error });
    return null;
  }
};

export const loadServices = (userId) => async (dispatch) => {
  try {
    const client = await loadClient(userId);

	// const update = dispatch(udpateClient(client._id, { ...client, paymentmethods: [] }))
	// console.log({client})

    const { data } = await services.find({
      query: { client: client._id },
    });

    const dataServices = data.map(service => {
      if (client.locations.length > 0 && service.locationid) {
        const location = client.locations.filter(({ _id }) => _id === service.locationid)[0]
  
        service.location = location
        return service;
      }
      
      return service;
    })
    
    dispatch({
      type: 'LOAD_SERVICES',
      payload: { client, data: dataServices },
    });
  } catch (error) {
    console.log({ error });
    return null;
  }
};

export const loadServicesAdmin = () => async (dispatch) => {
  try {
    const { data } = await services.find({
      paginate: false,
      query: { $limit: 10000, $populate: ['client'] },
    });

    const dataServices = data.map(service => {
      if (service.client.locations.length > 0 && service.locationid) {
        const location = service.client.locations.filter(({ _id }) => _id === service.locationid)[0]
  
        service.location = location
        return service;
      }
      
      return service;
    })
    
    dispatch({
      type: 'LOAD_SERVICES',
      payload: { client: null, data: dataServices },
    });
  } catch (error) {
    console.log({ error });
    return null;
  }
};

export const adjustPayment = (ticket) => async (dispatch, getState) => {
  try {
    const {
      form: { adjustPayment: { values } },
    } = getState();

    const newServiceReadings = ticket.service.serviceReadings.map(service => {
      if (service._id === ticket.ticket._id) {
        return {
          ...service,
          status: values.status,
          comment: values.comment
        }
      }

      return service;
    })

    const service = {
      ...ticket.service,
      serviceReadings: newServiceReadings,
    }

    await services.patch(service._id, service);
    dispatch(loadServicesAdmin());
  } catch (error) {
    console.log({ error });
    return null;
  }
};



