import React, { useState, useEffect } from 'react';
import { Table, Button, Pagination, Label } from 'semantic-ui-react';
import ModalVerify from './ModalVerify';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { setEditClient } from '../../actions';
import APIclient from '../../api';

const ClientsList = ({ showPendings, verify, setEditClient }) => {
  const history = useHistory();
  const [clients, setClients] = useState([]);
  const [page,setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const paginate = 10;
  const feathersService = APIclient.service('/api/clients');

  useEffect(() => {
    loadClientsData(paginate, page);
  }, []);

  const loadClientsData = (paginate,activePage) => {
    (async () => {
      const loadClients = await feathersService.find({
        query: { $populate: ['user'], $limit: paginate, $skip: activePage * paginate },
      });

      if ((loadClients !== undefined && loadClients.data.length !== 0)) {      
        const _totalPages = parseInt(loadClients.total / paginate)      
        const _rest = loadClients.total % paginate > 0 ? 1 : 0
        setTotalPages(_totalPages+_rest);
      }
      setClients(loadClients.data);
    })();
  };

  const handlePaginationChange = (e, { activePage }) => {
    setPage(activePage)
    loadClientsData(paginate, activePage - 1) 
  }

  const handleEditClient = (client) => {
    setEditClient(client);
    history.push('/clientes2/editar/' + client._id);
  };

  const updateClientStatus = async (client, status) => {
    const data = { active: status }
    const updateUser = await feathersService.patch(client._id, data);
    handlePaginationChange(client,data);
  }

  return (
    <div style={{ paddingBottom: 100 }}>
      <Table singleLine striped style={{ marginTop: 20 }} unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Nombre</Table.HeaderCell>
            <Table.HeaderCell>Telefono</Table.HeaderCell>
            <Table.HeaderCell>Correo</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Opciones</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {clients.length > 0 ? (
            clients.map((client) => (
              <Table.Row key={client._id}>
                <Table.Cell
                  children={client.user.name}
                  collapsing={false}
                ></Table.Cell>
                <Table.Cell
                  children={client.user.phone}
                  collapsing={false}
                ></Table.Cell>
                <Table.Cell
                  children={client.user.email}
                  collapsing={false}
                ></Table.Cell>
                <Table.Cell collapsing={false}>
                  {client.active && <Label color='green' horizontal>Activo</Label>}
                  {!client.active && <Label color='red' horizontal>Inactivo</Label>}
                </Table.Cell>
                <Table.Cell collapsing={false} width="2">
                  {showPendings && (
                    <ModalVerify verify={verify} id={client._id} />
                  )}

                  {!showPendings && (
                    <>
                      <Button
                        icon="pencil"
                        onClick={() => {
                          handleEditClient(client);
                        }}
                        circular
                        size="mini"
                      />
                  {client.active && <Button circular icon="eye slash" size="mini" color='grey' data={client._id} onClick={() => updateClientStatus(client, false)} />}
                  {!client.active && <Button circular icon="eye" size="mini" color='green' data={client._id} onClick={() => updateClientStatus(client, true)} />}
                    </>
                  )}
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell></Table.Cell>
              <Table.Cell>Sin información</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      <Pagination
          style={{ marginTop: 10, marginBottom: 30 }}
          floated="right"
          activePage={page === 0 ? page + 1 : page}
          onPageChange={handlePaginationChange}
          totalPages={totalPages}
        />
    </div>
  );
};

const mapStateToProps = (state) => ({
  editClient: state.editclient ?? null,
});

const mapDispatchToProps = (dispatch) => ({
  setEditClient: (data) => dispatch(setEditClient(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientsList);
