import React from 'react';
import { Modal, Button } from 'semantic-ui-react';
import { Form, Fields } from '../../../components';

const PaymentModal = ({ isOpen, onClose, ticket, onSubmit }) => {
  return (
    <Modal open={isOpen} onClose={() => onClose(false)} closeIcon>
      <Modal.Header>
        Ajustar pago
      </Modal.Header>
        
      <Modal.Content>
        <Form
          form="adjustPayment"
          enableReinitialize={true}
          initialValues={{ comment: ticket.ticket.comment || '' }}
        >
          <Fields
            fieldsData={{
              status: {
                name: 'status',
                label: 'Status',
                placeholder: '',
                type: 'select',
                options: [
                  { key: 'Overdued', value: 'Overdued', text: 'Vencido' },
                  { key: 'Paid', value: 'Paid', text: 'Pagado' },
                  { key: 'Canceled', value: 'Canceled', text: 'Cancelado' },
                ],
                required: true,
                disabled: false,
              },
              comment: {
                name: 'comment',
                label: 'Comentario',
                placeholder: '',
                type: 'textarea',
                required: false,
                disabled: false,
              },
            }}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button content="Cancelar" type="button" onClick={() => onClose(false)} />
        <Button primary content="Guardar" onClick={() => { onSubmit(ticket); onClose(false); }} />
      </Modal.Actions>
    </Modal>
  );
};

export default PaymentModal;
