import React from 'react';
import { connect } from 'react-redux';
import { Header, Container } from 'semantic-ui-react';
import { createClient } from '../../actions';
import ClientsList from './List';
import usePermissions from '../../hooks/usePermissions';

const Users = (props) => {
  const [hasPermission, typeUser] = usePermissions(props.location.pathname, props.history);

  return (
    <div>
      {hasPermission && 
        <Container>
          <Header as="h2" floated="left" className="header_page">
            Clientes
          </Header>         
          <ClientsList typeUser={typeUser} />
        </Container>
      }
      {!hasPermission && <div>No disponible.</div>}
    </div>
  );
};

const mapStateToProps = (state) => ({
  FormUsers: state.form.FormUsers?.values ?? null,
});

const mapDispatchToProps = (dispatch) => ({
  createClient: () => dispatch(createClient()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
