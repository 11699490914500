import React , { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { Button, Table,Pagination,Icon } from 'semantic-ui-react';

const CSVChargedList = ({loadedCSV,history,totalPages,activePage,onPageChange}) => {


  const handleDetail = (values) => {
    history.push('/csvuploaddetail', {
      detailValues: values,
    });
  };

  return (
    <div>
      <Table singleLine striped style={{ marginTop: 20 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Fecha de carga</Table.HeaderCell>
            <Table.HeaderCell collapsing={true}>Ver detalle</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {loadedCSV.length > 0 ? (
            loadedCSV.map((theObject) => (
              <Table.Row key={theObject._id}>
                <Table.Cell children={theObject.chargeDate}></Table.Cell>
                <Table.Cell>
                  <Button
                    circular
                    icon="table"
                    size="mini"
                    color="green"
                    data={theObject.id}
                    onClick={() => handleDetail(theObject.values)}
                  />
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell>Sin información</Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      <Pagination
          style={{ marginTop: 10, marginBottom: 30 }}
          floated="right"
          activePage={activePage === 0 ? activePage + 1 : activePage}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
    </div>
  );
};

export default withRouter(CSVChargedList);
