import React from 'react';
import { useSelector } from 'react-redux';

const ADMIN_ALLOWED = [
  '/',
  '/perfil',
  '/perfil/domicilios',
  '/perfil/facturacion',
  '/clientes',
  '/clientes2',
  '/usuarios',
  '/recibos',
  '/clientes/edit',
  '/usuarios/editar',
  '/perfil/servicios',
  '/usuarios/nuevo',
  '/perfil/metodospago'
];

const CUSTOMER_ALLOWED = [
  '/recibos',
  '/perfil',
  '/perfil/domicilios',
  '/perfil/facturacion',
  '/perfil/servicios',
  '/perfil/metodospago'
];

const ASSISTANT_ALLOWED = [
  '/',
  '/perfil',
  '/perfil/domicilios',
  '/perfil/facturacion',
  '/clientes',
  '/clientes2',
  '/usuarios',
  '/recibos',
  '/clientes/edit',
  '/perfil/servicios',
  '/perfil/metodospago',
  '/usuarios/nuevo',
] 

const usePermissions = (pathname, history) => {
  const type = useSelector((state) => state.users.user?.type || null);
  const [hasPermission, setHasPermission] = React.useState(false);

  React.useEffect(() => {
    if (!type) {
      history.push('/');
    }

    if (type === 'reestocker') {
      if (ADMIN_ALLOWED.includes(pathname)) setHasPermission(true);
    }

    if (type === 'customer') {
      if (CUSTOMER_ALLOWED.includes(pathname)) setHasPermission(true);
    }

    if (type === 'assistant') {
      if (ASSISTANT_ALLOWED.includes(pathname)) setHasPermission(true);
    }
  }, []);

  return [hasPermission, type];
};

export default usePermissions;
