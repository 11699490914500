import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Button, Icon, Header } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import { Form, Fields } from '../../components';
import APIclient from '../../api';
import usePermissions from '../../hooks/usePermissions';

const ClientProfileBillingInfo = ({
   state,
    props,
    history,
    clientProfileBillingInfo,
}) => {
    const [hasPermission] = usePermissions('/clientes2', history);
    const { id, index, locid } = useParams();
    const feathersService = APIclient.service('/api/clients');
    let clientData =state?.editclient.editclient??null;
    let editIndex = index ==='nuevo'?null:index;
    let editBI_ID = locid==='0'? null:locid;
    let editValues = locid? clientData?.billingInformation[index]: null;

    useEffect(() => {
        if (clientData === null) {
            history.push(`/clientes2`);
        }
    });

    const handleSave = () => {
        if (editIndex == null) {
            if (!clientData.billingInformation) {
                clientData.billingInformation = [];
            }
            clientData.billingInformation.push(clientProfileBillingInfo);
        } else {
            const newState = clientData.billingInformation.map((obj) =>
                obj._id === editBI_ID ? clientProfileBillingInfo : obj
            );
            clientData.billingInformation = [];
            clientData.billingInformation = newState;           
        }

        (async () => {
            feathersService
                .patch(clientData._id, clientData)
                .then((response) => {
                    props.history.goBack();;
                });
        })();
    };

    return (
        <Container style={{ display: 'flex', flexDirection: 'column' }}>
            {hasPermission &&
                <>
                    <Header as="h2" floated="left" className="header_page">
                        {index === 'nuevo' ? 'Agregar información de facturación' : 'Editar información de facturación'}
                    </Header>
                    <Form form="clientProfileBillingInfo" initialValues={editValues}>
                        <Fields
                            fieldsData={{
                                type: {
                                    name: 'type',
                                    label: 'Tipo de persona',
                                    placeholder: 'Seleccione el tipo de persona',
                                    type: 'select',
                                    required: false,
                                    disabled: false,
                                    options: [
                                        { key: 'Moral', value: 'Moral', text: 'Moral' },
                                        { key: 'Fisica', value: 'Fisica', text: 'Física' },
                                    ],
                                    columns: 5,
                                },
                                rfc: {
                                    name: 'rfc',
                                    label: 'RFC',
                                    placeholder: 'RFC',
                                    type: 'text',
                                    required: false,
                                    disabled: false,
                                    columns: 10,
                                },
                                name1: {
                                    name: 'name1',
                                    label: 'Primer nombre',
                                    placeholder: 'Primer nombre',
                                    type: 'text',
                                    required: true,
                                    disabled: false,
                                    columns: 5,
                                },
                                name2: {
                                    name: 'name2',
                                    label: 'Segundo nombre',
                                    placeholder: 'Segundo nombre',
                                    type: 'text',
                                    required: false,
                                    disabled: false,
                                    columns: 5,
                                },
                                lastName1: {
                                    name: 'lastName1',
                                    label: 'Primer apellido',
                                    placeholder: 'Primer apellido',
                                    type: 'text',
                                    required: true,
                                    disabled: false,
                                    columns: 5,
                                },
                                lastName2: {
                                    name: 'lastName2',
                                    label: 'Segundo apellido',
                                    placeholder: 'Segundo apellido',
                                    type: 'text',
                                    required: false,
                                    disabled: false,
                                    columns: 5,
                                },
                                phone: {
                                    name: 'phone',
                                    label: 'Teléfono',
                                    placeholder: 'Télefono',
                                    type: 'text',
                                    required: false,
                                    disabled: false,
                                    columns: 5,
                                },
                                street: {
                                    name: 'street',
                                    label: 'Calle',
                                    placeholder: 'Calle',
                                    type: 'text',
                                    required: false,
                                    disabled: false,
                                    columns: 7,
                                },
                                number: {
                                    name: 'number',
                                    label: 'Número',
                                    placeholder: 'Número',
                                    type: 'text',
                                    required: false,
                                    disabled: false,
                                    columns: 4,
                                },
                                internalNumber: {
                                    name: 'internalNumber',
                                    label: 'Número interior',
                                    placeholder: 'Número interior',
                                    type: 'text',
                                    required: false,
                                    disabled: false,
                                    columns: 4,
                                },
                                suburb: {
                                    name: 'suburb',
                                    label: 'Colonia',
                                    placeholder: 'Colonia',
                                    type: 'text',
                                    required: false,
                                    disabled: false,
                                    columns: 7,
                                },
                                zipCode: {
                                    name: 'zipCode',
                                    label: 'Código postal',
                                    placeholder: 'Código postal',
                                    type: 'text',
                                    required: false,
                                    disabled: false,
                                    columns: 3,
                                },
                                city: {
                                    name: 'city',
                                    label: 'Ciudad',
                                    placeholder: 'Ciudad',
                                    type: 'text',
                                    required: false,
                                    disabled: false,
                                    columns: 7,
                                },
                                state: {
                                    name: 'state',
                                    label: 'Estado',
                                    placeholder: 'Estado',
                                    type: 'text',
                                    required: false,
                                    disabled: false,
                                    columns: 7,
                                },
                                references: {
                                    name: 'references',
                                    label: 'Referencias',
                                    placeholder: 'Referencias',
                                    type: 'textarea',
                                    required: false,
                                    disabled: false,
                                    columns: 15,
                                },
                                additionalInfo: {
                                    name: 'additionalInfo',
                                    label: 'Información adicional',
                                    placeholder: 'Información adicional',
                                    type: 'textarea',
                                    required: false,
                                    disabled: false,
                                    columns: 15,
                                },
                            }}
                        />
                        <div className="buttons" style={{ padding: 30, paddingRight: 60, marginBottom: 100 }}>
                            <Button type="button" color="red" onClick={handleSave} floated="right">
                                <Icon name="save" className="detailIcon" />
                            &nbsp;Guardar
                            </Button>
                            <Button type="button" onClick={props.history.goBack} floated="right">Cancelar</Button>
                        </div>
                    </Form>
                </>
            }
            {!hasPermission && <div>No disponible.</div>}
        </Container>
    );
};

const mapStateToProps = (state, props) => ({
    clientProfileBillingInfo: state.form.clientProfileBillingInfo?.values ?? null,
    editClient: state.editclient.editclient??null,
    props: props,
    state:state
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientProfileBillingInfo);
