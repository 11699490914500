import React from 'react';
import { Form } from 'semantic-ui-react';

const SelectComponent = ({
  meta,
  input: { onChange, ...restPropsInput },
  placeholder,
  label,
  disabled,
  options,
}) => {
  return (
    <Form.Select
      error={meta.error && meta.touched ? meta.error : false}
      label={label}
      placeholder={placeholder}
      onChange={(_, { value }) => onChange(value)}
      {...restPropsInput}
      disabled={disabled}
      options={options}
    />
  );
};

export default React.memo(SelectComponent);
