import React, { useRef } from 'react';
import { Modal } from 'semantic-ui-react';
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Image } from '@react-pdf/renderer';
import logo from '../../assets/images/logo-reestock.png';
import moment from 'moment';

const Ticket = ({ isOpen, onClose, ticket: info }) => {
  return (
    <Modal open={isOpen} onClose={() => onClose(false)} closeIcon>
      <Modal.Header>
        <PDFDownloadLink document={<DocumentPDF info={info} />} fileName="recibodepago.pdf">
          {({ blob, url, loading, error }) => (loading ? 'Cargando PDF...' : 'Descargar')}
        </PDFDownloadLink>
      </Modal.Header>
      <Modal.Content>
        <DocumentPDF info={info} />
      </Modal.Content>
    </Modal>
  );
};

const DocumentPDF = ({ info }) => {
  const { user, ticket, service } = info
console.log({service})
  const { street = '', zipCode = '', number = '', suburb = '', city = '', state = '' } = service.location

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.column}>
            <View style={styles.section}>
              <View style={{ display: 'flex', flexDirection: 'column', width: 160 }}>
                <View style={styles.logoContainer}>
                  <Image src={logo} alt="logo" style={styles.logo} />
                </View>
              </View>
            </View>
          </View>
          <View style={styles.column}>
            <View style={styles.headerAmount}><Text>Fecha límite de pago</Text></View>
            <View style={styles.datePayment}><Text>{ticket.outageDate}</Text></View>
          </View>
        </View>
        <View style={styles.section}>
          <View style={styles.column}>
            <Text style={{ fontSize: 14, lineHeight: 1, fontWeight: 'bold', marginTop: 20 }}>
              Cliente: 
            </Text>
            <Text style={styles.text}>
              {user.name}
            </Text>
            <Text style={styles.text}>{street.toUpperCase()} {number}</Text>
            <Text style={styles.text}>
              {suburb.toUpperCase()}, CP {zipCode}
            </Text>
            <Text style={styles.text}>
              {city.toUpperCase()}, {state.toUpperCase()}, MEXICO
            </Text>
          </View>
          <View style={styles.column}>
            <View style={styles.headerAmount}><Text style={styles.text}>Total a pagar del período facturado</Text></View>
            <View style={styles.amountPayment}><Text style={styles.textAmount}>$ {parseFloat(ticket.total).toFixed(2)}</Text></View>
          </View>
        </View>
        <View style={styles.section}>
          <View style={{ width: '100%' }}>
            <View style={styles.detail}>
              <View style={styles.displayFlex}>
                <View style={styles.detailRow}>
                  <View style={styles.detailRowFlex}>
                    <View style={styles.detailLeft}><Text style={styles.text} style={styles.title}>Periodo</Text></View>
                    <View style={styles.detailRight}></View>
                  </View>
                </View>
                <View style={styles.detailRow}>
                  <View style={styles.detailRowFlex}>
                    <View style={styles.detailLeft}><Text style={styles.text12}>Fecha de inicio</Text></View>
                    <View style={styles.detailRight}><Text style={styles.text12}>{ticket.startDate}</Text></View>
                  </View>
                </View>
                <View style={styles.detailRow}>
                  <View style={styles.detailRowFlex}>
                    <View style={styles.detailLeft}><Text style={styles.text12}>Fecha de corte</Text></View>
                    <View style={styles.detailRight}><Text style={styles.text12}>{ticket.outageDate}</Text></View>
                  </View>
                </View>
                <View style={styles.detailRow}>
                  <View style={styles.detailRowFlex}>
                    <View style={styles.detailLeft}><Text style={styles.text12}>Días transcurridos</Text></View>
                    <View style={styles.detailRight}><Text style={styles.text12}>{ticket.days}</Text></View>
                  </View>
                </View>
                <View style={styles.detailRow}>
                  <View style={styles.detailRowFlex}>
                    <View style={styles.detailLeft}><Text style={styles.text12}>Cargas en el mes</Text></View>
                    <View style={styles.detailRight}><Text style={styles.text12}>{ticket.chargesCount}</Text></View>
                  </View>
                </View>
                <View style={styles.detailRow} />
                <View style={styles.detailRow} />
                <View style={styles.detailRow} />
                <View style={styles.detailRow} />
              </View>

              <View style={styles.displayFlex}>
                <View style={styles.detailRow}>
                  <View style={styles.detailRowFlex}>
                    <View style={styles.detailLeft}><Text style={styles.text12} style={styles.title}>Consumo</Text></View>
                    <View style={styles.detailRight}></View>
                  </View>
                </View>
                <View style={styles.detailRow}>
                  <View style={styles.detailRowFlex}>
                    <View style={styles.detailLeft}>
                      <Text style={styles.text12}>Lectura inicial: (litros)</Text>
                    </View>
                    <View style={styles.detailRight}><Text style={styles.text12}>{ticket.initialAmount}</Text></View>
                  </View>
                </View>
                <View style={styles.detailRow}>
                  <View style={styles.detailRowFlex}>
                    <View style={styles.detailLeft}>
                      <Text style={styles.text12}>Carga del mes: (litros)</Text>
                    </View>
                    <View style={styles.detailRight}><Text style={styles.text12}>{ticket.periodTotalCharge}</Text></View>
                  </View>
                </View>
                <View style={styles.detailRow}>
                  <View style={styles.detailRowFlex}>
                    <View style={styles.detailLeft}>
                      <Text style={styles.text12}>Lectura final: (litros)</Text>
                    </View>
                    <View style={styles.detailRight}><Text style={styles.text12}>{parseFloat(ticket.initialAmount) + parseFloat(ticket.periodTotalCharge) - parseFloat(ticket.periodTotalConsumption)}</Text></View>
                  </View>
                </View>
                <View style={styles.detailRow}>
                  <View style={styles.detailRowFlex}>
                    <View style={styles.detailLeft}><Text style={styles.text12}>Litros consumidos</Text></View>
                    <View style={styles.detailRight}><Text style={styles.text12}>{ticket.periodTotalConsumption}</Text></View>
                  </View>
                </View>
                <View style={styles.detailRow}>
                  <View style={styles.detailRowFlex}>
                    <View style={styles.detailLeft}><Text style={styles.text12}>Costo por litro</Text></View>
                    <View style={styles.detailRight}><Text style={styles.text12}>$ {parseFloat(ticket.unitCost).toFixed(2)}</Text></View>
                  </View>
                </View>
                <View style={styles.detailRow}>
                  <View style={styles.detailRowFlex}>
                    <View style={styles.detailLeft}><Text style={styles.text12}>Porcentaje inicial</Text></View>
                    <View style={styles.detailRight}><Text style={styles.text12}>{parseFloat(ticket.initialPercentage).toFixed(2)}</Text></View>
                  </View>
                </View>
                <View style={styles.detailRow}>
                  <View style={styles.detailRowFlex}>
                    <View style={styles.detailLeft}><Text style={styles.text12}>Porcentaje de lectura</Text></View>
                    <View style={styles.detailRight}><Text style={styles.text12}>{parseFloat(ticket.readingPercentage1).toFixed(2)}</Text></View>
                  </View>
                </View>
                <View style={styles.detailRow}>
                  <View style={styles.detailRowFlex}>
                    <View style={styles.detailLeft}><Text style={styles.text12}>Consumo del mes</Text></View>
                    <View style={styles.detailRight}><Text style={styles.text12}>{parseFloat(ticket.readingConsumption1).toFixed(2)}</Text></View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.section}>
          <View style={styles.column}></View>
          <View style={styles.column}>
            <View style={{ display: 'flex', flexDirection: 'column', paddingTop: 20, paddingBottom: 20 }}>
              <View style={styles.detailRowFlex}>
                <View style={styles.detailLeft}><Text style={styles.text}>Facturación del periodo</Text></View>
                <View style={styles.detailRight}><Text style={styles.text}>$ {parseFloat(ticket.periodTotal).toFixed(2)}</Text></View>
              </View>
              <View style={styles.detailRowFlex}>
                <View style={styles.detailLeft}><Text style={styles.text}>Adeudo anterior</Text></View>
                <View style={styles.detailRight}><Text style={styles.text}>$ {(parseFloat(ticket.total) - parseFloat(ticket.periodTotal)).toFixed(2)}</Text></View>
              </View>
              <View style={styles.detailRowFlex}>
                <View style={styles.detailLeft}><Text style={styles.text}>Total</Text></View>
                <View style={styles.detailRight}><Text style={styles.text}>$ {parseFloat(ticket.total).toFixed(2)}</Text></View>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.divider} />
        <View style={styles.section}>
          <View style={styles.column}>
            <View style={styles.infoReestock}>
              <Text style={styles.infoReestockTitle}>
                ReeStock Mexicana SAPI de C.V.
              </Text>
              <Text style={styles.text12}>Avenida Kiki Murillo 102 – 28 La Primavera</Text>
              <Text style={styles.text12}>CP 80300 Culiacán, Sinaloa, MX</Text>
              <Text style={styles.text12}>Teléfono (667) 189 3640 RFC: RME-171115-JP8</Text>
            </View>
          </View>
          <View style={styles.column}>
            <Text style={styles.thankyou}>¡GRACIAS POR SU PAGO!</Text>
          </View>
        </View>
        <View style={styles.section}></View>
        <View style={styles.section}></View>
      </Page>
    </Document>
  )
}

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column'
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    flexBasis: 100,
    margin: 10,
  },
  column: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 100,
    height: 100,
    borderRadius: 50,
    backgroundColor: '#e03a3e'
  },
  logo: {
    width: 68,
    height: 14
  },
  infoReestock: {
    display: 'flex',
    flex: 1,
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    fontSize: 12,
    color: '#173148',
  },
  infoReestockTitle: {
    fontSize: 14,
  },
  thankyou: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#173148',
    textAlign: 'right'
  },
  headerAmount: {
    color: '#173148',
    textAlign: 'right',
    fontSize: 14,
    fontWeight: 'bold',
  },
  title: {
    color: '#e03a3e',
    fontSize: 14,
    fontWeight: 'bold',
  },
  datePayment: {
    fontWeight: 'bold',
    fontSize: 18,
    textAlign: 'right',
  },
  amountPayment: {
    fontWeight: 'bold',
    fontSize: 24,
    textAlign: 'right',
  },
  displayFlex: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  detail: {
    display: 'flex',
    flexDirection: 'row',
    border: 1,
    borderWidth: 1,
    borderColor: '#c4c4c4',
    paddingTop: 10,
    paddingBottom: 10,
    marginTop: 10
  },
  detailRow: {
    display: 'flex',
    flex: 1,
    padding: '0px 20px',
  },
  detailRowFlex: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  detailLeft: {
    display: 'flex',
    flex: 2,
    justifyContent: 'flex-end'
  },
  detailRight: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
  },
  divider: {
    marginTop: 10,
    width: '100%',
    height: 8,
    backgroundColor: 'red',
  },
  textAmount: {
    fontSize: 36,
    fontWeight: 'bold'
  },
  text: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  text12: {
    fontSize: 12,
    fontWeight: 'bold',
  }
});

export default Ticket;
     
