import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Icon } from 'semantic-ui-react';
import { Form, Fields } from '../../components';
import APIclient from '../../api';

const ClientServices = ({
  open: isOpen,
  onClose,
  props,
  clientServiceForm,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [editValues, setEditValues] = useState(null);
  let clientData = props.clientData;

  let clientServices = props.clientServices;
  let editIndex = props.editIndex;
  let editID = props.editID;

  useEffect(() => {
    if (!isLoaded) {
      setIsLoaded(true);
      setEditValues(clientServices[editIndex] ?? null);
    }
  });

  const feathersService = APIclient.service('/api/services');

  const fieldsNewService = {
    locationid: {
      name: 'locationid',
      label: 'Domicilio',
      placeholder: 'Seleccione el domicilio',
      type: 'select',
      options: parseLocations(clientData.locations),
      required: false,
      disabled: false,
      columns: 8,
    },
    paymentMethod: {
      name: 'paymentMethod',
      label: 'Método de pago',
      placeholder: 'Seleccione el método de pago',
      type: 'select',
      options: parsePaymentMethods(clientData.paymentmethods),
      required: false,
      disabled: false,
      columns: 8,
    },
    billinginfoid: {
      name: 'billinginfoid',
      label: 'Información de facturación',
      placeholder: 'Seleccione la información de facturación',
      type: 'select',
      options: parseBI(clientData.billingInformation),
      required: false,
      disabled: false,
      columns: 8,
    },
    type: {
      name: 'type',
      label: 'Tipo de servicio',
      placeholder: 'Seleccione la tipo de servicio',
      type: 'select',
      options: [{ key: 'Gas', value: 'Gas', text: 'Gas' }],
      required: false,
      disabled: false,
      columns: 8,
    },
    iotID: {
      name: 'iotID',
      label: 'IoT ID',
      placeholder: 'Iot ID',
      type: 'text',
      required: false,
      disabled: false,
      columns: 8,
    },
    capacity: {
      name: 'capacity',
      label: 'Capacidad del servicio',
      placeholder: 'Capacidad del sertvicio',
      type: 'text',
      required: false,
      disabled: false,
      columns: 4,
    },
    contractStart: {
      name: 'contractStart',
      label: 'Inicio del contrato',
      placeholder: 'Fecha de inicio del contrato',
      type: 'calendar',
      required: false,
      disabled: false,
      columns: 8,
    },/*
     type: {
       name: 'status',
       label: 'Estatus',
       placeholder: 'Seleccione el estatus',
       type: 'hidden',
       options: [{ key: 'Active', value: 'Active', text: 'Active' }],
       required: false,
       disabled: false,
       hiddden:true,
       columns: 8,
     },*/
    comments: {
      name: 'comments',
      label: 'Descripción',
      placeholder: 'Comentarios',
      type: 'text',
      required: false,
      disabled: false,
      columns: 16,
    },

    automaticbilling: {
      name: 'automaticbilling',
      label: 'Pago domiciliado',
      placeholder: 'Pago domiciliado',
      type: 'checkbox',
      required: false,
      disabled: false
    }
  }

  const fieldsEditService = {
    locationid: {
      name: 'locationid',
      label: 'Domicilio',
      placeholder: 'Seleccione el domicilio',
      type: 'select',
      options: parseLocations(clientData.locations),
      required: false,
      disabled: false,
      columns: 8,
    },
    paymentMethod: {
      name: 'paymentMethod',
      label: 'Método de pago',
      placeholder: 'Seleccione el método de pago',
      type: 'select',
      options: parsePaymentMethods(clientData.paymentmethods),
      required: false,
      disabled: false,
      columns: 8,
    },
    billinginfoid: {
      name: 'billinginfoid',
      label: 'Información de facturación',
      placeholder: 'Seleccione la información de facturación',
      type: 'select',
      options: parseBI(clientData.billingInformation),
      required: false,
      disabled: false,
      columns: 8,
    },
    type: {
      name: 'type',
      label: 'Tipo de servicio',
      placeholder: 'Seleccione la tipo de servicio',
      type: 'select',
      options: [{ key: 'Gas', value: 'Gas', text: 'Gas' }],
      required: false,
      disabled: false,
      columns: 8,
    },
    iotID: {
      name: 'iotID',
      label: 'IoT ID',
      placeholder: 'Iot ID',
      type: 'text',
      required: false,
      disabled: false,
      columns: 8,
    },
    capacity: {
      name: 'capacity',
      label: 'Capacidad del servicio',
      placeholder: 'Capacidad del sertvicio',
      type: 'text',
      required: false,
      disabled: false,
      columns: 4,
    },
    contractStart: {
      name: 'contractStart',
      label: 'Inicio del contrato',
      placeholder: 'Fecha de inicio del contrato',
      type: 'calendar',
      required: false,
      disabled: false,
      columns: 8,
    },
    contractEnd: {
      name: 'contractEnd',
      label: 'Fin del contrato',
      placeholder: 'Fecha de fin de contrato',
      type: 'calendar',
      required: false,
      disabled: false,
      hidden:true,
      columns: 8,
    },
    comments: {
      name: 'comments',
      label: 'Descripción',
      placeholder: 'Comentarios',
      type: 'text',
      required: false,
      disabled: false,
      columns: 16,
    },

    automaticbilling: {
      name: 'automaticbilling',
      label: 'Pago domiciliado',
      placeholder: 'Pago domiciliado',
      type: 'checkbox',
      required: false,
      disabled: false
    }
  }


  function parseLocations(locations) {
    return locations.map(({ name, street, number, suburb, _id }) => {
      return {
        key: _id,
        text: `${name} ${street} ${number} ${suburb}`,
        value: _id,
      };
    });
  }

  function parseBI(BIs) {
    return BIs.map((BIs) => {
      return { key: BIs._id, text: BIs.rfc, value: BIs._id };
    });
  }

  function parsePaymentMethods(PMs) {
    return PMs.map((PM) => {
      return { key: PM.id, text: PM.card.brand + ' ' + PM.card.last4, value: PM.id };
    });
  }

  const handleSave = () => {
    let serviceToSave = {
      client: clientData._id,
      locationid: clientServiceForm.locationid,
      billinginfoid: clientServiceForm.billinginfoid,
      type: clientServiceForm.type,
      iotID: clientServiceForm.iotID,
      paymentMethod: clientServiceForm.paymentMethod,
      capacity: clientServiceForm.capacity,
      contractStart: clientServiceForm.contractStart,
      contractEnd: clientServiceForm.contractEnd,
      status: clientServiceForm.status,
      comments: clientServiceForm.comments,
      overduedPayments: 0, // clientServiceForm.overduedPayments,
      type: "Gas",
      serviceReadings: [],
      stripeUserID: clientData.stripeUserID ?? "",
      automaticbilling:clientServiceForm.automaticbilling??false
    };

    if (editValues == null) {
      (async () => {
        const response = await feathersService
          .create(serviceToSave)
          .then((response) => {
            props.setEditorOpen(false);
            props.loadClientsServices();
          });
      })();
    } else {
      (async () => {
        const response = await feathersService
          .patch(editID, serviceToSave)
          .then((response) => {
            props.setEditorOpen(false);
            props.loadClientsServices();
          });
      })();
    }
  };

  return (
    <Modal
      open={isOpen}
      closeIcon
      closeOnEscape={true}
      closeOnDimmerClick={true}
      onClose={() => onClose(false)}
    >
      <Modal.Header>
        {editIndex === -1
          ? `Agregar información del servicio`
          : `Editar información del servicio`}
      </Modal.Header>
      <Modal.Content>
        {isLoaded &&
          <Form form="clientServiceForm" enableReinitialize={true} initialValues={editValues}>
            <Fields
              fieldsData={editIndex === -1? fieldsNewService : fieldsEditService}
            />
          </Form>
        }
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => onClose(false)}>Cancelar</Button>
        <Button color="red" floated="right" onClick={handleSave}>
          <Icon name="save" className="detailIcon" />
          &nbsp;Guardar
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps = (state, props) => ({
  clientServiceForm: state.form.clientServiceForm?.values ?? null,
  props: props,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ClientServices));
