import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Header, Container, Tab, Input, Grid } from 'semantic-ui-react';
import List from '../List';
import Ticket from '../Ticket';
import {
  loadServicesAdmin,
  adjustPayment
} from '../../../actions';
import PaymentModal from './PaymentModal';

const TicketsAdmin = ({ loadServicesAdmin, services, user, adjustPayment }) => {
  const [servicesSearch, setServicesSearch] = useState([]);
  const [ isPDFOpen, setIsPDFOpen ] = useState(false);
  const [isPaymentOpen, setIsPaymentOpen] = useState(false);
  const [ selectedService, setSelectedService ] = useState(null);
  const [ticketSelected, setTicketSelected] = useState(null);

  useEffect(() => {
    loadServicesAdmin();
  }, []);

  useEffect(() => {
    setServicesSearch(services);
  }, [services])

  const onClickDownload = (ticket) => {
    const service = servicesSearch.filter(serv => serv.iotID === ticket.iotID)[0];
    setTicketSelected({ user, ticket, service });
    setIsPDFOpen(true);
  };

  const handleChangeService = (e, { value }) => {
    const newServices = services.filter(service => service.iotID.includes(value));
    setServicesSearch(newServices);
  }

  const onClickPayment = (ticket) => {
    const service = servicesSearch.filter(serv => serv.iotID === ticket.iotID)[0];
    setTicketSelected({ user, ticket, service });
    setIsPaymentOpen(true);
  }

  const handleSubmitPayment = async (ticket) => {
    await adjustPayment(ticket);
  }

  const panes = [
    {
      menuItem: {
        key: 'pendientes',
        icon: 'file alternate outline',
        content: 'Pendientes',
      },
      render: () => {
        const servicesWithInfo = servicesSearch.map(service => {
          const readingsWithInfo = service.serviceReadings.map(sr => 
            ({ ...sr,
              iotID: service.iotID,
              location: service?.location?.name ?? '',
              service: service.capacity
            }))
          
          return {
            ...service,
            serviceReadings: readingsWithInfo
          }
        })

        const bla = servicesWithInfo.map(service => service.serviceReadings)
        const sr = [].concat.apply([], bla);

        const serviceReadingsPendings = sr.filter(serv => serv.status !== 'Paid')

        return (
          <Tab.Pane>
            {serviceReadingsPendings.length > 0 ? (
              <div style={{ paddingBottom: 30 }}>
                <List
                  onClickPayment={onClickPayment}
                  serviceReadings={serviceReadingsPendings}
                  onClickDownload={onClickDownload}
                  admin={true}
                  />
              </div>
            ) : (
                <em>No hay recibos pendientes</em>
              )}
          </Tab.Pane>
        );
      },
    },
    {
      menuItem: {
        key: 'historico',
        icon: 'clock outline',
        content: 'Historico',
      },
      render: () => {
        const servicesWithInfo = servicesSearch.map(service => {
          const readingsWithInfo = service.serviceReadings.map(sr => 
            ({ ...sr,
              iotID: service.iotID,
              location: service?.location?.name ?? '',
              service: service.capacity
            }))

          return {
            ...service,
            serviceReadings: readingsWithInfo
          }
        })

        const bla = servicesWithInfo.map(service => service.serviceReadings)
        const sr = [].concat.apply([], bla);
        const serviceReadingsPaid = sr.filter(serv => serv.status === 'Paid')

        return (
          
          <Tab.Pane>
            {serviceReadingsPaid.length > 0 ? (
              <div>
                <List
                  onClickPayment={onClickPayment}
                  serviceReadings={serviceReadingsPaid}
                  onClickDownload={onClickDownload}
                  admin={true}
                  />
              </div>
            ) : <em>Aún no hay información</em>}
          </Tab.Pane>
        )
      },
    },
    {
      menuItem: {
        key: 'all',
        icon: 'archive',
        content: 'Todo',
      },
      render: () => {
        const servicesWithInfo = servicesSearch.map(service => {
          const readingsWithInfo = service.serviceReadings.map(sr => 
            ({ ...sr,
              iotID: service.iotID,
              location: service?.location?.name ?? '',
              service: service.capacity
            }))

          return {
            ...service,
            serviceReadings: readingsWithInfo
          }
        })

        const bla = servicesWithInfo.map(service => service.serviceReadings)
        const sr = [].concat.apply([], bla);

        const serviceReadingsAll = sr

        return (
          <Tab.Pane>
            {serviceReadingsAll.length > 0 ? (
              <div>
                <List
                  serviceReadings={serviceReadingsAll}
                  onClickDownload={onClickDownload}
                  admin={true}
                  />
              </div>
            ) : <em>Aún no hay información</em>}
          </Tab.Pane>
        )
      }
    }
  ];

  return (
    <div>
      <Container>
        <Grid>
          <Grid.Column width="16">
            <Header as="h2" floated="left">
              Recibos
            </Header>
          </Grid.Column>
          <Grid.Column width="16">
            <Input fluid placeholder="Buscar número de servicio..." onChange={handleChangeService} />
          </Grid.Column>
        </Grid>
        
        
        {/* {services.length > 1 &&
          <Select
            placeholder="Seleccione el servicio"
            options={services.map(({ iotID }) =>
              ({ key: iotID, value: iotID, text: iotID }))} />
        }
        <br /> */}
        <br />

        <Tab panes={panes} />

        {isPDFOpen && <Ticket
          isOpen={isPDFOpen}
          onClose={setIsPDFOpen}
          ticket={ticketSelected}
          />
        }

        {isPaymentOpen && 
          <PaymentModal
            isOpen={isPaymentOpen}
            onSubmit={handleSubmitPayment}
            onClose={setIsPaymentOpen}
            ticket={ticketSelected} />
        }
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  services: state.clients.services,
  userId: state.users.user._id,
  user: state.users.user
});

const mapDispatchToProps = (dispatch) => ({
  loadServicesAdmin: () => dispatch(loadServicesAdmin()),
  adjustPayment: (ticket) => dispatch(adjustPayment(ticket))
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketsAdmin);
