import React from 'react';
import { connect } from 'react-redux';
import { Button, Icon, Header, Container } from 'semantic-ui-react';
import { createClient } from '../../actions';
import UsersList from './list';
import usePermissions from '../../hooks/usePermissions';

const Users = (props) => {
  const [hasPermission, typeUser] = usePermissions(props.location.pathname, props.history);

  const handleNewClick = () => {
    let path = `usuarios/nuevo`;
    props.history.push(path);
  };

  return (
    <div>
      {hasPermission && 
        <Container>
          <Header as="h2" floated="left" className="header_page">
            Usuarios
          </Header>

          <Button color="red" floated="right" onClick={handleNewClick}>
            <Icon name="add" className="detailIcon" />
            &nbsp;Crear Usuario
          </Button>
          
          <UsersList typeUser={typeUser} />
        </Container>
      }
      {!hasPermission && <div>No disponible.</div>}
    </div>
  );
};

const mapStateToProps = (state) => ({
  FormUsers: state.form.FormUsers?.values ?? null,
});

const mapDispatchToProps = (dispatch) => ({
  createClient: () => dispatch(createClient()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
