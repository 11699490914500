import jwt from 'jsonwebtoken';
import { loadUser, loadClient } from './';

export const loadApp = () => async (dispatch) => {
  const token = localStorage.getItem('reestock-jwt');

  if (token !== undefined && token) {
    const decoded = jwt.decode(token);

    if (decoded.userId !== undefined) {
      const { userId } = decoded;
      const user = await dispatch(loadUser(userId));
      // const { isValidated } = await loadClient(userId);

      if (user) {
        dispatch({
          type: 'LOAD_USER',
          payload: { isLogged: true, isValidated: true, user },
        });
      }

      dispatch({ type: 'LOAD_APP' });
      return;
    }

    dispatch({ type: 'LOAD_APP' });
    return;
  }

  dispatch({ type: 'LOAD_APP' });
  return;
};
