import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Fields } from '../../components';
import { Button, Header, Grid } from 'semantic-ui-react';
import { createUser, updateUser, signup } from '../../actions';
import usePermissions from '../../hooks/usePermissions';

const objValues = {
  name: '',
  email: '',
  phone: '',
  username: '',
  password: '',
  confirm: '',
  type: 'customer'
}

const UsersForm = ({ 
  props, usersForm, createUser, updateUser, location: { pathname, state }, history }) => {

  const [hasPermission, typeUser] = usePermissions(pathname, history);
  const [initialValues, setInitialValues] = useState(objValues)
  const [isDisabled, setIsDisabled] = useState(false)

  const isEdit = pathname.split('/')[2] === 'editar'

  useEffect(() => {
    
    if (isEdit) {
      const detailValues = state?.detailValues || null
      setInitialValues(detailValues)
    }
  }, [])

  const handleSubmit = async () => {
    setIsDisabled(true);

    if (usersForm) {
      if (isEdit) {
        let userToSave = {
          _id: usersForm._id ? usersForm._id : '',
          email: usersForm.email ? usersForm.email : '',
          username: usersForm.username ? usersForm.username : '',
          name: usersForm.name ? usersForm.name : '',
          phone: usersForm.phone ? usersForm.phone : '',
          type: usersForm.type ? usersForm.type : '',
        };

        const res = await updateUser(userToSave);

        if (res) props.history.push('/usuarios');
      }
      else {
        let userToSave = {
          email: usersForm.email ? usersForm.email : '',
          name: usersForm.name ? usersForm.name : '',
          username: usersForm.username ? usersForm.username : '',
          password: usersForm.password ? usersForm.password : '',
          confirm: usersForm.confirm ? usersForm.confirm : '',
          phone: usersForm.phone ? usersForm.phone : '',
          type: usersForm.type ? usersForm.type : '',
        };

        const res = await createUser(userToSave);

        if (res) props.history.push('/usuarios');
      }
    }

    setIsDisabled(false);
  };

  const structForm = () => {
    const options = typeUser === 'assistant' ? [
      { key: 'customer', value: 'customer', text: 'Cliente' },
      { key: 'assistant', value: 'assistant', text: 'Colaborador' }
    ] : 
    [
      { key: 'customer', value: 'customer', text: 'Cliente' },
      { key: 'reestocker', value: 'reestocker', text: 'Administrador' },
      { key: 'assistant', value: 'assistant', text: 'Colaborador' }
    ]

    const passwords = !state?.detailValues ? {
      password: {
        name: 'password',
        label: 'Contraseña',
        placeholder: '',
        type: 'text',
        typeInput: 'password',
        required: true,
        disabled: false,
        columns: 8,
      },
      confirm: {
        name: 'confirm',
        label: 'Confirmar',
        placeholder: '',
        type: 'text',
        typeInput: 'password',
        required: true,
        disabled: false,
        columns: 8,
      },
    } : {}

    const data = {
      name: {
        name: 'name',
        label: 'Nombre',
        placeholder: '',
        type: 'text',
        required: true,
        disabled: false,
        columns: 8,
      },
      email: {
        name: 'email',
        label: 'Email',
        placeholder: '',
        type: 'text',
        required: true,
        disabled: false,
        columns: 8,
      },
      phone: {
        name: 'phone',
        label: 'Celular',
        placeholder: '',
        type: 'text',
        required: true,
        disabled: false,
        columns: 8,
      },
      username: {
        name: 'username',
        label: 'Usuario',
        placeholder: '',
        type: 'text',
        required: true,
        disabled: false,
        columns: 8,
      },
      ...passwords,
      role: {
        name: 'type',
        label: 'Tipo de usuario',
        placeholder: 'Seleccione el tipo de usuario',
        type: 'select',
        required: true,
        disabled: false,
        options,
        columns: 8,
      }
    }
    return data;
  }

  return (
    <div>
      {hasPermission && 
        <Grid>
          <Grid.Column width="16">
            <Header as="h2" floated="left" className="header_page">
              {isEdit ? 'Editar usuario' : 'Crear usuario'}
            </Header>
          </Grid.Column>
          <Grid.Column width="16">
            <Form
              form="signupForm"
              onSubmit={handleSubmit}
              enableReinitialize={true}
              initialValues={initialValues}
            >
              <Fields
                fieldsData={structForm()}
              />

              <div style={{ marginTop: 40 }}>
                <Button type="submit" primary icon="save" floated="right" disabled={isDisabled}>
                  {isEdit ? 'EDITAR' : 'CREAR'}
                </Button>
                <Button onClick={() => history.push('/usuarios')} floated="right">
                  Cancelar
                </Button>
              </div>
            </Form>
          </Grid.Column>
        </Grid>
      }
      {!hasPermission && <div>No disponible.</div>}
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  usersForm: state.form.signupForm?.values ?? null,
  props: props,
});

const mapDispatchToProps = (dispatch) => ({
  createUser: (data) => dispatch(createUser(data)),
  updateUser: (data) => dispatch(updateUser(data)),
  signup: () => dispatch(signup()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersForm);
