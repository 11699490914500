import React from 'react';
import { Sidebar, Segment, Grid, Menu } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

const styles = {
  sidebar: { margin: 0, padding: 0, marginTop: '90px !important' },
  grid: { width: '100%', padding: 0, margin: 0, marginTop: 40 },
  gridColumns: { width: '100%', padding: 0, margin: 0 },
  menuItem: { paddingTop: 20, paddingBottom: 20 },
};

const SideBar = ({ active, history }) => {
  const handleItemClick = (e, { to }) => {
    history.push(to);
  };

  return (
    <Sidebar
      as={Segment}
      visible={true}
      style={styles.sidebar}
      className="menu-sidebar"
    >
      <Grid style={styles.grid}>
        <Grid columns={1} style={styles.gridColumns}>
          <Grid.Column>
            <Menu secondary vertical style={{ width: '100%' }}>
              <Menu.Item
                name="clientes"
                icon="user"
                to="/clientes"
                active={active === 'clientes'}
                onClick={handleItemClick}
                style={styles.menuItem}
              />
              {/* <Menu.Item
                name="clientes"
                icon="user"
                to="/clientes"
                active={active === 'clientes'}
                onClick={handleItemClick}
                style={styles.menuItem}
              /> */}
              <Menu.Item
                name="Recibos"
                icon="tag"
                to="/recibos"
                active={active === 'recibos'}
                onClick={handleItemClick}
                style={styles.menuItem}
              />
              <Menu.Item
                name="usuarios"
                icon="users"
                to="/usuarios"
                active={active === 'usuarios'}
                onClick={handleItemClick}
                style={styles.menuItem}
              />
              <Menu.Item
                name="Carga CSV"
                icon="file excel"
                to="/csvupload"
                active={active === 'csvupload'}
                onClick={handleItemClick}
                style={styles.menuItem}
              />
            </Menu>
          </Grid.Column>
        </Grid>
      </Grid>
    </Sidebar>
  );
};

export default withRouter(SideBar);
