import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Button, Table, Confirm, Label, Pagination } from 'semantic-ui-react';
import APIclient from '../../api';


const UsersList = ({ history, typeUser }) => {
  const feathersService = APIclient.service('/api/users');
  const [loadedUsers, setLoadedUsers] = useState([]);
  const [page,setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const paginate = 10;

  useEffect(() => {
    loadUsersData(paginate,page);
  }, [loadedUsers.length != 0]);

  const loadUsersData = (paginate,activePage) => {
    (async () => {
      const loadUsers = await feathersService.find({
        query: { $limit: paginate, $skip: activePage * paginate },
      });

      if ((loadUsers !== undefined && loadUsers.data.length !== 0)) {      
        const _totalPages = parseInt(loadUsers.total / paginate)      
        const _rest = loadUsers.total % paginate > 0 ? 1 : 0
        setTotalPages(_totalPages+_rest)
      }
      
      setLoadedUsers(loadUsers.data);
    })();
  };

  const handleDetail = (values) => {
    history.push('/usuarios/editar', {
      detailValues: { ...values, username: values.userName },
    });
  };

  const updateUserStatus = async (user, status) => {
    const data = { active: status }
    const updateUser = await feathersService.patch(user._id, data);
    loadUsersData(paginate, page >= 1 ? page - 1 : page);
  }

  const handlePaginationChange = (e, { activePage }) => {
    setPage(activePage)
    loadUsersData(paginate, activePage - 1) 
  }

  return (
    <div>
      <Table singleLine striped style={{ marginTop: 20 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>NombreUsuario</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Teléfono</Table.HeaderCell>
            <Table.HeaderCell>Tipo</Table.HeaderCell>
            <Table.HeaderCell>Acciones</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {loadedUsers.length > 0 ? (
            loadedUsers.map((user) => (
              <Table.Row key={user._id}>
                <Table.Cell
                  children={user.name}
                  collapsing={false}
                ></Table.Cell>
                <Table.Cell
                  children={user.email}
                  collapsing={false}
                ></Table.Cell>
                <Table.Cell
                  children={user.phone}
                  collapsing={false}
                ></Table.Cell>
                <Table.Cell>
                  {user.type === 'customer' && (
                    <Label color="blue" horizontal>
                      Cliente
                    </Label>
                  )}
                  {user.type === 'reestocker' && (
                    <Label color="grey" horizontal>
                      Admin
                    </Label>
                  )}
                </Table.Cell>
                <Table.Cell>
                  {typeUser === 'assistant' && user.type === 'reestocker' ? <div /> : 
                    <>
                      <Button
                        circular
                        icon="pencil"
                        size="mini"
                        data={user.id}
                        onClick={() => handleDetail(user)}
                      />
                  
                      {user.active && <Button circular icon="eye slash" size="mini" color='grey' data={user._id} onClick={() => updateUserStatus(user, false)} />}
                      {!user.active && <Button circular icon="eye" size="mini" color='green' data={user._id} onClick={() => updateUserStatus(user, true)} />}
                    </>
                  }
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
              <Table.Row>
                <Table.Cell></Table.Cell>
                <Table.Cell>Sin información</Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
            )}
        </Table.Body>
      </Table>
      <Pagination
          style={{ marginTop: 10, marginBottom: 30 }}
          floated="right"
          activePage={page === 0 ? page + 1 : page}
          onPageChange={handlePaginationChange}
          totalPages={totalPages}
        />
    </div>
  );
};

export default withRouter(UsersList);
