import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Icon,
  Header,
  Container,
  Divider,
  Table,
} from 'semantic-ui-react';
import { createClient } from '../../actions';
import usePermissions from '../../hooks/usePermissions';
import APIclient from '../../api';
import ClientProfileLocation from './newlocation';
//import history from './history';

const ClientProfileLocations = ({ state, history }) => {
  const feathersService = APIclient.service('/api/clients');
  const paginate = 20;
  const initialValues = state.users.user;
  const [hasPermission] = usePermissions(window.location.pathname, history);
  const [loadedClients, setLoadedClients] = useState([]);
  const [editorOpen, setEditorOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const [editID, setEditID] = useState('');

  useEffect(() => {
    loadClientsData();
  }, [loadedClients.length != 0]);

  const loadClientsData = (activePage = 0) => {
    (async () => {
      const loadClients = await feathersService
        .find({
          query: {
            user: initialValues._id,
            $limit: paginate,
            $skip: activePage * paginate,
          },
        })
        .then((loadClients) => {
          setLoadedClients(loadClients.data);
        });
    })();
  };

  const handleEditLocation = (index, id) => {
    setEditIndex(index);
    setEditID(id);
    setEditorOpen(true);
  };

  const handleNewLocation = () => {
    setEditIndex(-1);
    setEditorOpen(true);
  };

  return (
    <div>
      {hasPermission && (
        <Container>
          <Header as="h2">
            Domicilios
            <Button color="red" floated="right" onClick={handleNewLocation}>
              <Icon name="add" className="detailIcon" />
              &nbsp;Agregar Domicilio
            </Button>
          </Header>
          <Divider />
          <Table singleLine striped style={{ marginTop: 20 }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Nombre</Table.HeaderCell>
                <Table.HeaderCell>Ciudad</Table.HeaderCell>
                <Table.HeaderCell>Estado</Table.HeaderCell>
                <Table.HeaderCell collapsing={true}>Acciones</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {loadedClients.length > 0 &&
              loadedClients[0].locations.length > 0 ? (
                loadedClients[0].locations.map((location, index) => (
                  <Table.Row key={location._id}>
                    <Table.Cell
                      children={location.name}
                      collapsing={false}
                    ></Table.Cell>
                    <Table.Cell
                      children={location.city}
                      collapsing={false}
                    ></Table.Cell>
                    <Table.Cell
                      children={location.state}
                      collapsing={false}
                    ></Table.Cell>
                    <Table.Cell>
                      <Button
                        circular
                        icon="pencil"
                        size="mini"
                        color="green"
                        data={location._id}
                        onClick={() => handleEditLocation(index, location._id)}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell></Table.Cell>
                  <Table.Cell>Sin información</Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>

          {editorOpen && (
            <ClientProfileLocation
              open={editorOpen}
              onClose={setEditorOpen}
              clientData={loadedClients[0]}
              editIndex={editIndex}
              editLocationID={editID}
              setEditorOpen={setEditorOpen}
            />
          )}
        </Container>
      )}
      {!hasPermission && <div>No disponible.</div>}
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  FormUsers: state.form.FormUsers?.values ?? null,
  props: props,
  state: state,
});

const mapDispatchToProps = (dispatch) => ({
  createClient: () => dispatch(createClient()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientProfileLocations);
