export const validateEmail = (email) => {
  if (!email) {
    return 'Ingresa un correo';
  } else if (checkFormatEmail(email)) {
    return 'Tu correo electrónico es incorrecto';
  } else return null;
};

const validateCVV = (cvv = '') => {
  if (cvv.length < 2) {
    return 'Tu cvv no es correcto';
  }
};

const checkFormatEmail = (email) =>
  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) ? true : false;

const genericValidate = (value) => (!value ? `Ingresar un valor` : null);

export const getValidateFunction = (key) => {
  const typeValidation = {
    email: validateEmail,
    cvv: validateCVV,
  };
  return typeValidation?.[key] ?? genericValidate;
};
