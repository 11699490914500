import React from 'react';
import { Table, Button, Label } from 'semantic-ui-react';

const List = ({ serviceReadings, onClickPayment, onClickDownload, admin = false, service }) => {
  return (
    <div>
      {!admin && 
        <div style={{ paddingLeft: 10 }}>
          <strong>No. de Servicio:</strong> {service.iotID} <br />
          <strong>Domicilio:</strong> {service.location.name}
        </div>
      }
      <Table singleLine striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Periodo de Consumo</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Concepto</Table.HeaderCell>
            <Table.HeaderCell>Monto a Pagar</Table.HeaderCell>
            {admin && 
              <>
                <Table.HeaderCell>No. de Servicio</Table.HeaderCell>
                <Table.HeaderCell>Domicilio</Table.HeaderCell>
              </>
            }
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {serviceReadings.map((ticket) => (
            <Table.Row key={ticket._id}>
              <Table.Cell><small>{ticket.startDate} - {ticket.outageDate}</small></Table.Cell>
              <Table.Cell>
                {ticket.status === 'Overdued' && <Label color="red">Vencido</Label>}
                {ticket.status === 'Canceled' && <Label color="red">Cancelado</Label>}
                {ticket.status === 'Waiting' && <Label color="yellow">Pendiente</Label>}
                {ticket.status === 'Paid' && <Label color="green">Pagado</Label>}
              </Table.Cell>
              <Table.Cell><small>Consumo de Gas LP del Periodo</small></Table.Cell>
              <Table.Cell>$ {parseFloat(ticket.total).toFixed(2)}</Table.Cell>
              {admin && 
                <>
                  <Table.Cell>{ticket.iotID}</Table.Cell>
                  <Table.Cell>{ticket.location}</Table.Cell>
                </>
              }
              <Table.Cell>
                {admin && <Button
                  circular
                  color="yellow"
                  icon="dollar sign"
                  size="tiny"
                  onClick={() => onClickPayment(ticket)}
                />
                }
                <Button
                  circular
                  icon="download"
                  size="tiny"
                  onClick={() => onClickDownload(ticket)}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
    
  );
};

export default React.memo(List);
