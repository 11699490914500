import React, { Fragment } from 'react';
import { Form } from 'semantic-ui-react';

const CheckBoxComponent = ({
  input: { value, name, onChange, ...restPropsInput },
  placeholder,
  label,
  disabled,
  toggle = true,
  styleContainer = {},
}) => {
  return (
    <div style={styleContainer}>
      <Form.Checkbox
        checked={value === '' ? false : value}
        name={name}
        toggle={toggle}
        onChange={(_, { checked }) => onChange(checked)}
        {...restPropsInput}
        placeholder={placeholder}
        disabled={disabled}
        label={<label>{label}</label>}
      />
    </div>
  );
};

export default React.memo(CheckBoxComponent);
