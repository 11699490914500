import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Header, Container, Tab, Grid, Select } from 'semantic-ui-react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import List from '../List';
import Ticket from '../Ticket';
import CheckoutForm from './CheckoutForm';
import CheckoutTicket from './CheckoutTicket';
import {
  loadServices,
  createPayment,
  createStripeClient,
  createStripePaymentMethod,
  udpateClient
} from '../../../actions';

const promise = loadStripe(
  'pk_live_LJ5am041kiGkuXQKFpmMkeS000hY5DpI60'
);

const TicketsCustomer = ({ loadServices, services, userId, user, client,  udpateClient, ...props }) => {
  const [ isPDFOpen, setIsPDFOpen ] = useState(false);
  const [ isOpenCheckout, setIsOpenCheckout ] = useState(false);
  const [ selectedService, setSelectedService ] = useState(null);
  const [servicesToPay, setServicesToPay] = useState([]);
  const [total, setTotal] = useState(0);
  const [ticketSelected, setTicketSelected] = useState(null);
  const [openVaucher, setOpenVaucher] = useState(false);
  const [vaucher, setVaucher] = useState(null);
	
  useEffect(() => {
    loadServices(userId);
  }, []);

  const handleOpenCheckout = (total, servicesPendings, id) => {
    setTotal(total)
    setServicesToPay({ servicesPendings, serviceId: id })
    setIsOpenCheckout(true)
  }

  const onClickDownload = (ticket) => {
    const service = selectedService ? selectedService : services[0]

    setTicketSelected({ user, ticket, service });
    setIsPDFOpen(true);
  };

  const handleSelectedService = (value) => {
    const service = services.filter(({ iotID }) => iotID === value)[0]
    setSelectedService(service);
  }

  const handleLoadServicesAgain = () => {
    loadServices(userId)
  }

  const panes = [
    {
      menuItem: {
        key: 'pendientes',
        icon: 'file alternate outline',
        content: 'Pendientes',
      },
      render: () => {
        var service = { serviceReadings: [] }

        if (services.length > 0 && !selectedService) {
          service = services[0]
        }
        else if (selectedService) {
          service = selectedService
        }

        const serviceReadingsPendings = service.serviceReadings.filter(serv => serv.status !== 'Paid' && serv.status !== 'Canceled')
        const total = serviceReadingsPendings.reduce((a, b) => +a + +b.total, 0);

        return (
          <Tab.Pane>
            {serviceReadingsPendings.length > 0 ? (
              <div style={{ paddingBottom: 30 }}>
                <List
                  serviceReadings={serviceReadingsPendings}
                  onClickDownload={onClickDownload}
                  service={service}
                  />

                <br />

                <Grid columns="2">
                  <Grid.Column>
                    
                  </Grid.Column>
                  <Grid.Column>
                    <Button
                      color="black"
                      floated="right"
                      style={{ marginTop: 18 }}
                      onClick={() => handleOpenCheckout(total, serviceReadingsPendings, service._id)}
                      content="Pagar servicio"
                    />
                    <h3 style={{ float: 'right', marginRight: 10 }}>Total: $ {total.toFixed(2)}</h3>
                  </Grid.Column>
                </Grid>
              </div>
            ) : (
                <em>No hay recibos pendientes</em>
              )}
          </Tab.Pane>
        );
      },
    },
    {
      menuItem: {
        key: 'historico',
        icon: 'clock outline',
        content: 'Historico',
      },
      render: () => {
        var service = { serviceReadings: [] }

        if (services.length > 0 && !selectedService) {
          service = services[0]
        }
        else if (selectedService) {
          service = selectedService
        }

        const serviceReadingsPaid = service.serviceReadings.filter(serv => serv.status === 'Paid')

        return (
          
          <Tab.Pane>
            {serviceReadingsPaid.length > 0 ? (
              <div>
                <List
                  serviceReadings={serviceReadingsPaid}
                  onClickDownload={onClickDownload}
                  service={service} />
              </div>
            ) : <em>Aún no hay información</em>}
          </Tab.Pane>
        )
      },
    },
    {
      menuItem: {
        key: 'all',
        icon: 'archive',
        content: 'Todo',
      },
      render: () => {
        var service = { serviceReadings: [] }

        if (services.length > 0 && !selectedService) {
          service = services[0]
        }
        else if (selectedService) {
          service = selectedService
        }

        const serviceReadingsAll = service.serviceReadings.filter(serv => serv.status !== 'Canceled')

        return (
          <Tab.Pane>
            {serviceReadingsAll.length > 0 ? (
              <div>
                <List
                  serviceReadings={serviceReadingsAll}
                  onClickDownload={onClickDownload}
                  service={service} />
              </div>
            ) : <em>Aún no hay información</em>}
          </Tab.Pane>
        )
      },
    },
  ];

  const handleCloseCheckout = (payment) => {
    setIsOpenCheckout(false);

    if (payment) {
      setVaucher(payment);
      setTimeout(() => setOpenVaucher(true), 1500);
    }
  }

  return (
    <div>
      <Container>
        <Header as="h2" floated="left">
          Recibos
        </Header>
        {services.length > 1 &&
          <>
            <Select
              style={{ float: 'right' }}
              placeholder="Seleccione el servicio"
              onChange={(_, { value }) => handleSelectedService(value)}
              options={services.map(({ iotID, capacity }) =>
                ({ key: iotID, value: iotID, text: `Gas cap. ${capacity} - ${iotID}` }))} />
          </>
        }
        
        <br />
        <br />

        <Tab panes={panes} />

        {isPDFOpen && <Ticket
          isOpen={isPDFOpen}
          onClose={setIsPDFOpen}
          ticket={ticketSelected}
          />
        }

        {isOpenCheckout && (
          <Elements stripe={promise}>
            <CheckoutForm
              isOpen={isOpenCheckout}
              onClose={handleCloseCheckout}
              total={total}
              user={user}
              services={servicesToPay}
              loadServices={loadServices}
              client={client}
              handleLoadServicesAgain={handleLoadServicesAgain}
              {...props} />
          </Elements>
        )}
      </Container>

      {openVaucher && 
         <CheckoutTicket
          isOpen={openVaucher}
          onClose={setOpenVaucher}
          ticket={{ user, ticket: vaucher, service: services[0] }}
          payment={vaucher}
          />
      }
    </div>
  );
};

const mapStateToProps = (state) => ({
  services: state.clients.services,
  userId: state.users.user._id,
  user: state.users.user,
  client: state.clients.client
});

const mapDispatchToProps = (dispatch) => ({
  loadServices: (clientId) => dispatch(loadServices(clientId)),
  createPayment: (stripeId, paymentId, serviceId, automaticBilling) => dispatch(
    createPayment(stripeId, paymentId, serviceId, automaticBilling)
  ),
  createStripeClient: (clientData) => dispatch(createStripeClient(clientData)),
  createStripePaymentMethod: (
    clientData,
    customer,
    saveMethodPayment,
    stripe,
    elements,
    CardElement) => dispatch(
    createStripePaymentMethod(
      clientData,
      customer,
      saveMethodPayment,
      stripe,
      elements,
      CardElement
    )
  ),
  udpateClient: (clientId, data) => dispatch(udpateClient(clientId, data))
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketsCustomer);
