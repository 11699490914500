import Notifications from 'react-notification-system-redux';
import jwt from 'jsonwebtoken';
import { SECRET_KEY } from '../configs/constants';
import client from '../api';
import { loadClient } from './';
import { validateEmail } from '../utils/validations';

const userService = client.service('api/users');

export const login = () => async (dispatch, getState) => {
  const {
    form: { loginForm },
  } = getState();

  const {
    values: { usuario: email, contraseña: password },
  } = loginForm;

  try {
    authenticate(email, password, dispatch);

  } catch (error) {
console.log({error})
    dispatch(
      Notifications.warning({
        title: 'Login incorrecto',
        message: 'No fue posible entrar con este usuario.',
      })
    );
    return false;
  }
};

export const signup = () => async (dispatch, getState) => {
  try {
    const {
      form: { signupForm },
    } = getState();

    const { values } = signupForm;

    // if (!values.terms) {
    //   return { success: false, msg: 'Debe aceptar los terminos y condiciones' };
    // }

    const validate = validateUser(values);

    if (validate.success) {
      const { username, ...moreProps } = values;

      const data = {
        ...moreProps,
        userName: username,
      };

      const response = await userService.create(data);

      if (response) {
        // authenticate(values.email, values.password, dispatch, false);

        return { success: true };
      }
    } else {
      dispatch(
        Notifications.warning({
          title: '',
          message: validate.msg,
        })
      );

      return { success: false, msg: validate.msg };
    }
  } catch (error) {
    if (error.message === 'email: value already exists.') {
      return {
        success: false,
        msg: 'Este email ya se encuentra registrado, intenta con otro',
      };
    } else {
      return {
        success: false,
        msg: 'Ocurrio un error al crear el usuario, intenta de nuevo.',
      };
    }
  }
};

export const sendEmailAgain = () => {};

export const logout = () => (dispatch) => {
  localStorage.removeItem('reestock-jwt');

  dispatch({
    type: 'LOAD_USER',
    payload: { isLogged: false, user: null },
  });
};

export const loadUser = (userId) => async (dispatch) => {
  try {
    const response = await userService.find({ query: { _id: userId } });

    if (response.data.length > 0) {
      return response.data[0];
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const createUser = (data, page) => async (dispatch) => {
  const validate = validateUser(data);

  if (validate.success) {
    try {
      const { username, ...moreProps } = data;
      const values = {
        ...moreProps,
        userName: username,
      };

      const response = await userService.create(values);

      return response;
    } catch (error) {
      return false;
    }
  } 
  else {
    dispatch(
      Notifications.warning({
        title: 'Error al guardar',
        message: validate.msg,
      })
    );

    return false
  }
};

export const authenticate = async (
  email,
  password,
  dispatch,
  isLogged = true
) => {
  try {
    const singupResult = await client.authenticate({
      strategy: 'local',
      email,
      password,
    });

    if (singupResult.accessToken !== undefined) {
      const decoded = jwt.decode(singupResult.accessToken);
      const uid = decoded.userId;

      const user = await userService.find({ query: { $limit: 1, _id: uid, active: true } });

      if (user.data.length > 0) {
        localStorage.setItem('reestock-jwt', singupResult.accessToken);
        localStorage.setItem(
          'panel',
          JSON.stringify({ email: user.data[0].email })
        );

        dispatch({
          type: 'LOAD_USER',
          payload: { isLogged, isValidated: true, user: user.data[0] },
        });
        return true;
      }
      else {
        localStorage.removeItem('reestock-jwt');

        dispatch(
          Notifications.warning({
            title: 'Login incorrecto',
            message: 'No fue posible entrar con este usuario.',
          })
        );

        return false;
      }
    } else {
console.log('nop')
      dispatch(
        Notifications.warning({
          title: 'Login incorrecto',
          message: 'No fue posible entrar con este usuario.',
        })
      );
    }
  } catch (error) {
console.log({error})
    dispatch(
      Notifications.warning({
        title: 'Login incorrecto',
        message: 'No fue posible entrar con este usuario.',
      })
    );
  }
};

export const updateUser = (data) => async (dispatch) => {

  const validate = validateUser(data, 'update');

  if (validate.success) {
    try {
      const { username, ...moreProps } = data;
      const values = {
        ...moreProps,
        userName: username,
      };

      const response = await userService.patch(data._id, values);

      return response;

    } catch (error) {
      return false;
    }
  } 
  else {
    dispatch(
      Notifications.warning({
        title: 'Error al guardar',
        message: validate.msg,
      })
    );

    return false
  }
};

const validateUser = (data, type = 'new') => {
  const { name, email, phone, username, password } = data;

  if (password === '' && password.length < 8) {
    return {
      success: false,
      msg: 'La contraseña debe de ser igual o mayor a 8 carácteres.',
    };
  }

  if (type !== 'update') {
    if (password !== data.confirm) {
      return {
        success: false,
        msg: 'La contraseña no coincide, intente de nuevo.',
      };
    }
  }

  if (name === '' || email === '' || phone === '' || username === '') {
    return {
      success: false,
      msg: 'Los datos estan incompletos, intente de nuevo.',
    };
  }

  const isNotEmail = validateEmail(email);

  if (isNotEmail) {
    return {
      success: false,
      msg: isNotEmail,
    };
  }

  return { success: true };
};

export const validateTokenSignup = (token) => async (dispatch) => {
  const decoded = jwt.decode(token, SECRET_KEY);

  if (decoded) {
    try {
      const responseUser = await userService.find({
        query: { email: decoded },
      });

      if (responseUser.data.length > 0) {
        const data = responseUser.data[0];

        const response = await userService.patch(data._id, {
          isEmailValidated: true,
        });

        if (response.isEmailValidated) {
          dispatch({
            type: 'LOAD_USER',
            payload: { isLogged: true, user: response },
          });

          return true;
        }

        return false;
      }
    } catch (error) {
      return false;
    }
  }
};

export const loadEditUser = (data) => async (dispatch) => {
  dispatch({
    type: 'EDIT_LOAD_INITIAL',
    payload: data,
  });
};
