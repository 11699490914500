import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { connect } from 'react-redux';
import Notifications from 'react-notification-system-redux';
import { loadApp, logout } from '../actions';
import Sidebar from './Sidebar';
import Login from './login';
import Signup from './signup';
import Header from './Header';

const App = ({
  isLoaded,
  loadApp,
  isLogged,
  location,
  history,
  user,
  children,
  logout,
  isValidated,
  notifications,
}) => {
  useEffect(() => {
    if (!isLoaded) {
      loadApp();
    }
  }, []);

  useEffect(() => {
    if (
      isLogged &&
      (location.pathname === '/login' || location.pathname === '/registro')
    ) {
      history.push('/');
    }
  });

  useEffect(() => {
    if (location.pathname === '/' && user?.type === 'customer')
      history.push('/recibos');
  });

  const renderLogin = () => {
    if (location.pathname === '/login' || location.pathname === '/') {
      return <Login />;
    } else {
      return children;
    }
  };

  const renderApp = () => {
    if (user.type === 'reestocker' || user.type === 'assistant') {
      return (
        <div>
          <Header user={user.name} type={user.type} logout={logout} />
          <Sidebar />
          <div style={{ marginLeft: 310, paddingRight: 40, paddingTop: 110 }}>
            <Container>{children}</Container>
          </div>
        </div>
      );
    } else {
      return (
        <>
          <Header user={user.name} type={user.type} logout={logout} />
          
          <Container style={{ paddingTop: 110 }}>
            {children}
          </Container>
        </>
      );
    }
  };

  return (
    <div className="app-main">
      {isLoaded && (
        <>
          {isLogged && renderApp()}

          {!isLogged && renderLogin()}
        </>
      )}

      {!isLoaded && <div>cargando...</div>}

      <Notifications notifications={notifications} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoaded: state.app.isLoaded,
  isLogged: state.users.isLogged,
  user: state.users.user,
  isValidated: state.users.isValidated,
  notifications: state.notifications,
});

const mapDispatchToProps = (dispatch) => ({
  loadApp: () => dispatch(loadApp()),
  logout: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
