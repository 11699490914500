import React from 'react';
import {
  TextField,
  TextInline,
  TextArea,
  Checkbox,
  Select,
  File,
  Calendar,
} from './basicForms';
import { Form } from 'semantic-ui-react';

const FieldForm = ({ type, ...props }) => {
  const component = {
    textInline: () => <TextInline {...props} />,
    text: () => <TextField {...props} />,
    textarea: () => <TextArea {...props} />,
    checkbox: () => <Checkbox {...props} />,
    select: () => <Select {...props} />,
    file: () => <File {...props} />,
    calendar: () => <Calendar {...props} />,
  };

  return <Form.Field>{component[type]()}</Form.Field>;
};

export default React.memo(FieldForm);
