import React, { useState } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';

const ModalVerify = ({ verify, id }) => {
  const [open, setOpen] = useState(false);

  return (
    <Modal
      basic
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
      trigger={
        <Button size="tiny" primary>
          Verificar &nbsp; <Icon name="check" />
        </Button>
      }
    >
      <Modal.Content>
        <p>
          Al verificar, se enviará un correo al usuario para que pueda utilizar
          su aplicación de reestock, ¿Deseas verificar al usuario?
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color="red" inverted onClick={() => setOpen(false)}>
          <Icon name="remove" /> No
        </Button>
        <Button
          color="green"
          inverted
          onClick={() => {
            verify(id);
            setOpen(false);
          }}
        >
          <Icon name="checkmark" /> Si
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ModalVerify;
