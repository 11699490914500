import React from 'react';
import { Form } from 'semantic-ui-react';

const TextArea = ({
  meta,
  input,
  type,
  placeholder,
  label,
  required,
  disabled,
}) => (
  <Form.TextArea
    label={required ? `${label}(*)` : label}
    error={meta.error && meta.touched ? meta.error : false}
    {...input}
    autoComplete="off"
    type={type}
    placeholder={placeholder}
    disabled={disabled}
  />
);

export default React.memo(TextArea);
