import axios from 'axios';
import qs from 'qs';
import { loadServices } from './';
import APIClient from '../api';
import Notifications from 'react-notification-system-redux';
const feathersClient = APIClient.service('/api/clients');
const feathersServices = APIClient.service('/api/services');

export const createPayment = (stripeId = null, paymentId = null, serviceId, automaticBilling) => async (dispatch, getState) => {
  const { users: { user: { _id } } } = getState() // : state.users.user._id,

  const url = 'https://api.reestock.com.mx/create-payment-intent';
  const dataOptions = {
    serviceId,
    stripeId,
    paymentId,
    automaticBilling
  }
try {  
  const options = {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(dataOptions),
      url,
    }

    const { data, status } = await axios(options);

    if (status !== 200) {
      // setError(data.msg);
      Notifications.warning({
        title: 'Pago no procesado',
        message: 'Su pago no pudo ser procesado correctamente, intente de nuevo.',
      })
      return { success: false, data };
    }
    else {
      Notifications.success({
        title: 'Pago exitoso',
        message: 'Su pago ha sido correctamente aplicado, gracias.',
      })

      dispatch(loadServices(_id));

      if (automaticBilling) {
        const service = await feathersServices.find({ query: { _id: serviceId } })
        const serviceData = service.data[0]
        const serviceUpdate = { 
          ...serviceData,
          automaticbilling: true,
          paymentMethod: paymentId
        }

        feathersServices.patch(serviceId, serviceUpdate);
      }

      return { success: true, data };
    }
  }
  catch (e){
    Notifications.warning({
      title: 'Pago no procesado',
      message: 'Su pago no pudo ser procesado correctamente, intente de nuevo.',
    })
    return { success: false, data: null };
  }
}
  
export const createStripeClient = (clientData) => async () => {
  const url = 'https://api.reestock.com.mx/create-customer';
  const data = { 'name': clientData.name, 'email': clientData.email };
  const options = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(data),
    url,
  };
  const stripeCustomer = await axios(options);
  clientData.stripeUserID = stripeCustomer.data.StripeId.id;
  await feathersClient.patch(clientData._id, clientData)
  return stripeCustomer;
}

export const createStripePaymentMethod = (
  clientData,
  customer,
  saveMethodPayment,
  stripe,
  elements,
  CardElement
) => async () => {

  if (!stripe || !elements) {
    return;
  }

  const cardElement = elements.getElement(CardElement);
  cardElement.customer = customer;

  const { error, paymentMethod } = await stripe.createPaymentMethod({
    type: 'card',
    card: cardElement,
  });

  if (error) {
      Notifications.warning({
        title: 'Pago no procesado',
        message: 'Su pago no pudo ser procesado correctamente, intente de nuevo.',
      })
  } else {
    const url = 'https://api.reestock.com.mx/add-card-customer';
    const data = { 'card': paymentMethod.id, 'customer': customer };
    const options = {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url,
    };
    
    const stripeCustomer = await axios(options);
    
    if (stripeCustomer.data.error !== undefined && stripeCustomer.data.error) {
      Notifications.warning({
        title: 'Pago no procesado',
        message: 'Su pago no pudo ser procesado correctamente, intente de nuevo.',
      })

     return false;
    } else {
      handleSavePaymentMethodToClient(stripeCustomer.data.card, clientData)

      return paymentMethod.id;
    }
  }
}

const handleSavePaymentMethodToClient = (card, clientData) => {
  if(!clientData.paymentmethods){
    let payments = [];
    clientData.paymentmethods = payments;
  }

  clientData.paymentmethods.push(card);
  
  (async () => {
    const response = await feathersClient
      .patch(clientData._id, clientData)
      .then((response) => {
        // props.setEditorOpen(false);
      });
  })();
};
