import React from 'react';
import { Form } from 'semantic-ui-react';

const InputComponent = ({
  meta,
  input,
  typeInput = 'text',
  placeholder,
  label,
  required,
  disabled,
}) => (
  <Form.Input
    label={required ? `${label}(*)` : label}
    error={meta.error && meta.touched ? meta.error : false}
    {...input}
    autoComplete="off"
    type={typeInput}
    placeholder={placeholder}
    disabled={disabled}
  />
);

export default React.memo(InputComponent);
