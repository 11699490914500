import React, { useState, createRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

const FileComponent = ({
  meta,
  input: { onChange, onBlur, value: _, ...restPropsInput },
  label,
  accept,
}) => {
  const [files, setFiles] = useState(null);
  const [error, setError] = useState('');
  const fileInputRef = createRef();

  const adaptFileEventToValue = (delegate) => (e) => {
    const file = e.target.files[0];

    if (file === undefined || file === null) {
      setError('No se pudo cargar el archivo');
    } else {
      const { name, type } = file;

      const data = {
        name,
        type,
        url: URL.createObjectURL(file),
        ext: name.split('.')[name.split('.').length - 1].toLowerCase(),
      };

      setFiles(data);
    }

    delegate(e.target.files[0]);
  };

  return (
    <div className="field">
      <Button
        content={label}
        icon="upload"
        label={{
          basic: true,
          pointing: 'left',
          content: 'Seleccionar un archivo',
        }}
        onClick={(e) => {
          e.preventDefault();
          fileInputRef.current.click();
        }}
      />
      <input
        id="file"
        {...restPropsInput}
        ref={fileInputRef}
        type="file"
        hidden
        accept={accept} // TODO: recibir estos parametros por props
        onChange={adaptFileEventToValue(onChange)}
        onBlur={adaptFileEventToValue(onBlur)}
      />
      {files && <span>{files.name}</span>}
    </div>
  );
};

FileComponent.defaultProps = {
  disabled: false,
  required: false,
  label: '',
  accept: '*',
  preview: true,
};

FileComponent.propTypes = {
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string,
  accept: PropTypes.string,
  preview: PropTypes.bool,
};

export default FileComponent;
