export default (
  state = {
    services: [],
    client: null,
  },
  action
) => {
  switch (action.type) {
    case 'LOAD_SERVICES': {
      return {
        ...state,
        services: action.payload.data,
        client: action.payload.client,
      };
    }
    default:
      return state;
  }
};
