export default (
  state = {
    isValidated: false,
    isLogged: false,
    user: null,
  },
  action
) => {
  switch (action.type) {
    case 'GET_USERS':
      return {
        ...state,
      };
    case 'LOAD_USER': {
      return {
        ...state,
        isLogged: action.payload.isLogged,
        isValidated: action.payload.isValidated,
        user: action.payload.user,
      };
    }
    case 'EDIT_LOAD_INITIAL':
      return {
        ...state,
        usersForm: action.payload.user,
      };
    default:
      return state;
  }
};
