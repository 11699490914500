import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Icon, Header, Container, Divider } from 'semantic-ui-react';
import { Form, Fields } from '../../components';
import usePermissions from '../../hooks/usePermissions';
import APIclient from '../../api';

const ClientProfile = ({ state, FormUsers, location, history }) => {
  const feathersService = APIclient.service('/api/clients');
  const usersService = APIclient.service('/api/users');
  const paginate = 20;
  const initialValues = state.users.user;
  const [hasPermission] = usePermissions(location.pathname, history);
  const [loadedClients, setLoadedClients] = useState([]);

  useEffect(() => {
    loadClientsData();
  }, [loadedClients.length != 0]);

  const loadClientsData = (activePage = 0) => {
    (async () => {
      const loadClients = await feathersService
        .find({
          query: {
            user: initialValues._id,
            $limit: paginate,
            $skip: activePage * paginate,
          },
        })
        .then((loadClients) => {
          setLoadedClients(loadClients.data);
        });
    })();
  };

  const handleSubmit = () => {
    if (FormUsers != null) {
      usersService.patch(FormUsers._id, FormUsers);
      window.location.reload(false);
    }
  };

  return (
    <div>
      {hasPermission && (
        <Container>
          <Header as="h2">
            Bienvenido {initialValues.name}
            <Button color="red" floated="right" onClick={handleSubmit}>
              <Icon name="save" className="detailIcon" />
              &nbsp;Actualizar
            </Button>
          </Header>
          <Divider />
          <Form
            form="userProfileForm"
            onSubmit={handleSubmit}
            initialValues={initialValues}
          >
            <Fields
              fieldsData={{
                email: {
                  name: 'email',
                  label: 'Email principal',
                  placeholder: 'Email principal',
                  type: 'text',
                  required: false,
                  disabled: true,
                },
                name: {
                  name: 'name',
                  label: 'Nombre de usuario',
                  placeholder: 'Nombre de usuario',
                  type: 'text',
                  required: false,
                  disabled: false,
                },
                password: {
                  name: 'password',
                  label: 'Contraseña',
                  placeholder: 'Contraseña',
                  typeInput: 'password',
                  type: 'text',
                  required: false,
                  disabled: false,
                },
                phone: {
                  name: 'phone',
                  label: 'Email de recuperación',
                  placeholder: 'Email de recuperación',
                  type: 'text',
                  required: false,
                  disabled: false,
                },
              }}
            />
          </Form>
        </Container>
      )}
      {!hasPermission && <div>No disponible.</div>}
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  FormUsers: state.form.userProfileForm?.values ?? null,
  props: props,
  state: state,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ClientProfile);
