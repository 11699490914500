import React from 'react';
import { Table, Button,Pagination } from 'semantic-ui-react';
import ModalVerify from './ModalVerify';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { setEditClient } from '../../actions';
import APIclient from '../../api';

const ClientsList = ({ clients, showPendings, verify, setEditClient,page,totalPages,handlePaginationChange }) => {
  const history = useHistory();
  const feathersService = APIclient.service('/api/clients');
  const handleEditClient = (client) => {
    setEditClient(client);
    history.push('/clientes/edit');
  };

  const updateClientStatus = async (client, status) => {
    const data = { active: status }
    const updateUser = await feathersService.patch(client._id, data);
    handlePaginationChange(client,data);
  }


  return (
    <div>
      <Table singleLine striped style={{ marginTop: 20 }} unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Nombre</Table.HeaderCell>
            <Table.HeaderCell>Telefono</Table.HeaderCell>
            <Table.HeaderCell>Correo</Table.HeaderCell>
            <Table.HeaderCell>Opciones</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {clients.length > 0 ? (
            clients.map((client) => (
              <Table.Row key={client._id}>
                <Table.Cell
                  children={client.user.name}
                  collapsing={false}
                ></Table.Cell>
                <Table.Cell
                  children={client.user.phone}
                  collapsing={false}
                ></Table.Cell>
                <Table.Cell
                  children={client.user.email}
                  collapsing={false}
                ></Table.Cell>
                <Table.Cell collapsing={false} width="2">
                  {showPendings && (
                    <ModalVerify verify={verify} id={client._id} />
                  )}

                  {!showPendings && (
                    <>
                      <Button
                        icon="pencil"
                        onClick={() => {
                          handleEditClient(client);
                        }}
                        circular
                        size="mini"
                      />
                  {client.active && <Button circular icon="eye slash" size="mini" color='grey' data={client._id} onClick={() => updateClientStatus(client, false)} />}
                  {!client.active && <Button circular icon="eye" size="mini" color='green' data={client._id} onClick={() => updateClientStatus(client, true)} />}
                    </>
                  )}
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell></Table.Cell>
              <Table.Cell>Sin información</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      <Pagination
          style={{ marginTop: 10, marginBottom: 30 }}
          floated="right"
          activePage={page === 0 ? page + 1 : page}
          onPageChange={handlePaginationChange}
          totalPages={totalPages}
        />
    </div>
  );
};

const mapStateToProps = (state) => ({
  editClient: state.editclient ?? null,
});

const mapDispatchToProps = (dispatch) => ({
  setEditClient: (data) => dispatch(setEditClient(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientsList);
