import React, { useState, useEffect } from 'react';
import {
  Divider,
  Header,
  Container,
  Tab,
  Icon,
  Table,
  Button,
} from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import usePermissions from '../../hooks/usePermissions';
import APIclient from '../../api';

const ClientsEditForm = ({ history }) => {
  const servicesService = APIclient.service('/api/services');
  const servicesClient = APIclient.service('/api/clients');
  const [hasPermission] = usePermissions('/clientes2', history);
  const [client, setClient] = useState(null);
  const [clientServices, setClientServices] = useState([]);
  const paginate = 20;
  const { id: clientID } = useParams();

  const loadClientsServices = (activePage = 0) => {
    (async () => {
      const responseClient = await servicesClient.find({ query: { _id: clientID, $populate: ['user'] }});

      if (responseClient.total > 0) {
        setClient(responseClient.data[0])

        servicesService
        .find({
          query: {
            client: clientID,
            $limit: paginate,
            $skip: activePage * paginate,
          },
        })
        .then((loadClientServices) => {
          setClientServices(loadClientServices.data);
        });
      }
    })();
  };

  
  useEffect(() => {
    if (clientID) {
      loadClientsServices();
    }
  }, []);

  const panes = [
    {
      menuItem: 'Domicilios',
      render: () => <Tab.Pane>{tabLocations()}</Tab.Pane>,
    },
    {
      menuItem: 'Información de facturación',
      render: () => <Tab.Pane>{tabBillingInfo()}</Tab.Pane>,
    },
    {
      menuItem: 'Servicios contratados',
      render: () => <Tab.Pane>{tabServices()}</Tab.Pane>,
    },
    {
      menuItem: 'Métodos de pago',
      render: () => <Tab.Pane>{tabPaymentMethods()}</Tab.Pane>,
    }
  ];

  const handleAddress = () => {
    history.push(`/clientes2/editar/${clientID}/nuevo/0`)
  }

  const handleEditAddress = (id,index) => {
    history.push(`/clientes2/editar/${clientID}/${index}/${id}`)
  }

  const handleBI = () => {
    history.push(`/clientes2/editarbi/${clientID}/nuevo/0`)
  }

  const handleEditBI = (id,index) => {
    history.push(`/clientes2/editarbi/${clientID}/${index}/${id}`)
  }

  const handleSVC = () => {
    history.push(`/clientes2/editarsvc/${clientID}/nuevo/0`)
  }

  const handleEditSVC = (id,index) => {
    history.push(`/clientes2/editarsvc/${clientID}/${index}/${id}`)
  }

  const tabLocations = () => {
    return (
      <div>
        <Header as="h2">
          Domicilios
          <Button color="red" floated="right" onClick={() => handleAddress()}>
            <Icon name="add" className="detailIcon" />
            &nbsp;Agregar Domicilio
          </Button>
        </Header>
        <Divider />
        <Table singleLine striped style={{ marginTop: 20 }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Nombre</Table.HeaderCell>
              <Table.HeaderCell>Ciudad</Table.HeaderCell>
              <Table.HeaderCell>Estado</Table.HeaderCell>
              <Table.HeaderCell collapsing={true}>Acciones</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {client.locations &&
              client.locations.length !== 0 ? (
                client.locations.map((location, index) => (
                  <Table.Row key={location._id}>
                    <Table.Cell
                      children={location.name}
                      collapsing={false}
                    ></Table.Cell>
                    <Table.Cell
                      children={location.city}
                      collapsing={false}
                    ></Table.Cell>
                    <Table.Cell
                      children={location.state}
                      collapsing={false}
                    ></Table.Cell>
                    <Table.Cell>
                      <Button
                        circular
                        icon="pencil"
                        size="mini"
                        color="green"
                        data={location._id}
                        onClick={() => handleEditAddress(location._id,index)}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell></Table.Cell>
                  <Table.Cell>Sin información</Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
              )}
          </Table.Body>
        </Table>
        <Divider />
      </div>
    );
  };

  const tabBillingInfo = () => {
    return (
      <div>
        <Header as="h2">
          Información de facturación
          <Button color="red" floated="right" onClick={() => handleBI()}>
            <Icon name="add" className="detailIcon" />
            &nbsp;Agregar información de facturación
          </Button>
        </Header>
        <Divider />
        <Table singleLine striped style={{ marginTop: 20 }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>RFC</Table.HeaderCell>
              <Table.HeaderCell>Nombre</Table.HeaderCell>
              <Table.HeaderCell collapsing={true}>Acciones</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {client.billingInformation &&
              client.billingInformation.length !== 0 ? (
                client.billingInformation.map(
                  (BI, index) => (
                    <Table.Row key={BI._id}>
                      <Table.Cell
                        children={BI.rfc}
                        collapsing={false}
                      ></Table.Cell>
                      <Table.Cell
                        children={`${BI.name1} ${BI.name2 || ''} ${BI.lastName1} ${BI.lastName2 || ''}`}
                        collapsing={false}
                      ></Table.Cell>
                      <Table.Cell>
                        <Button
                          circular
                          icon="pencil"
                          size="mini"
                          color="green"
                          data={BI._id}
                          onClick={() => handleEditBI(BI._id,index)}
                        />
                      </Table.Cell>
                    </Table.Row>
                  )
                )
              ) : (
                <Table.Row>
                  <Table.Cell></Table.Cell>
                  <Table.Cell>Sin información</Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
              )}
          </Table.Body>
        </Table>
      </div>
    );
  };

  const tabServices = () => {
    return (
      <div>
        <Header as="h2">
          Servicios Contratados
          <Button color="red" floated="right" onClick={() => handleSVC()}>
            <Icon name="add" className="detailIcon" />
            &nbsp;Agregar Servicio
          </Button>
        </Header>
        <Divider />
        <Table singleLine striped style={{ marginTop: 20 }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Tipo</Table.HeaderCell>
              <Table.HeaderCell>IoT</Table.HeaderCell>
              <Table.HeaderCell collapsing={true}>Acciones</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {clientServices.length !== 0 ? (
              clientServices.map((Svc, index) => (
                <Table.Row key={Svc._id}>
                  <Table.Cell
                    children={Svc.type}
                    collapsing={false}
                  ></Table.Cell>
                  <Table.Cell
                    children={Svc.iotID}
                    collapsing={false}
                  ></Table.Cell>
                  <Table.Cell>
                    <Button
                      circular
                      icon="pencil"
                      size="mini"
                      color="green"
                      data={Svc._id}
                      onClick={() => handleEditSVC(Svc._id,index)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
                <Table.Row>
                  <Table.Cell></Table.Cell>
                  <Table.Cell>Sin información</Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
              )}
          </Table.Body>
        </Table>
      </div>
    );
  };

  const tabPaymentMethods = () => {
    return (
      <div>
        <Header as="h2">
          Métodos de pago
        </Header>
        <Divider />
        <Table singleLine striped style={{ marginTop: 20 }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Descripcion</Table.HeaderCell>
              <Table.HeaderCell>Últimos 4 dígitos</Table.HeaderCell>
              <Table.HeaderCell>Valida hasta</Table.HeaderCell>           
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {client.paymentmethods &&
              client.paymentmethods.length !== 0 ? (
                client.paymentmethods.map(
                  (payment, index) => (
                    <Table.Row key={payment._id}>
                      <Table.Cell
                        children={payment.card.brand}
                        collapsing={false}
                      ></Table.Cell>
                      <Table.Cell
                        children={payment.card.last4}
                        collapsing={false}
                      ></Table.Cell>
                      <Table.Cell
                        children={payment.card.exp_month + '/' + payment.card.exp_year}
                        collapsing={false}
                      ></Table.Cell>
                    
                    </Table.Row>
                  )
                )
              ) : (
                <Table.Row>
                  <Table.Cell></Table.Cell>
                  <Table.Cell>Sin información</Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
              )}
          </Table.Body>
        </Table>

      </div>
    );
  };
  
  return (
    <div>
      {hasPermission && client && (
        
        <Container>
          <Header as="h2">
            Editar información del cliente:{' '}
            {client.user.name}
              <Button color="red" floated="right" onClick={() => { history.push('/clientes2') }}>
                <Icon name="arrow left" className="detailIcon" />
                &nbsp;Regresar
              </Button>
          </Header>
        
          <Divider />
          <Tab panes={panes} />
        </Container>
      )}
      {!hasPermission && <div>No disponible.</div>}
    </div>
  );
};

export default ClientsEditForm;
