import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Icon, Header, Message } from 'semantic-ui-react';
import { createClient, loadClients, verifyClient } from '../../actions';
import ClientsList from './list';
import usePermissions from '../../hooks/usePermissions';

const Clients = ({ history, location, loadClients, verifyClient }) => {
  const [showPendings, setShowPendings] = useState(false);
  const [activePage, setActivePage] = useState(0);
  const [clients, setClients] = useState([]);
  const [clientsPendings, setClientsPendings] = useState([]);
  const [hasPermission] = usePermissions(location.pathname, history);

  //const [page,setPage]=useState(0);
  const [totalPages,setTotalPages]=useState(0);
  const paginate = 10;

console.log('bla bla bla ');

  useEffect(() => {
    handleLoadClients(false,paginate,activePage);
    handleLoadClients(true,paginate,activePage);
  }, []);

  const handleLoadClients = async (pendings,paginate,activePage) => {
    const response = await loadClients(activePage, pendings,paginate);
  
    if ((response !== undefined && response.data.length !== 0)) {      
      const _totalPages = parseInt(response.total / paginate)      
      const _rest = _totalPages % paginate > 0 ? 1 : 0
      setTotalPages(_totalPages+_rest)
    }

	console.log({ response, _totalPages, _rest });
    pendings ? setClientsPendings(response.data) : setClients(response.data);

  };

  const handleVerify = async (id) => {
    await verifyClient(id);
    handleLoadClients(false);
    handleLoadClients(true);
  };

  const handleNewClick = () => {
    let path = `clientes/new/`;
    history.push(path);
  };

  const handlePaginationChange = (e, { activePage }) => {
    setActivePage(activePage)
    handleLoadClients(false,paginate,activePage - 1) 
  }

  return (
    <div>
      {hasPermission && (
        <>
          {clientsPendings.length > 0 && (
            <Message warning size="tiny">
              {`Tienes ${clientsPendings.length} clientes sin validar, `}
              <strong style={{ fontSize: 14 }}>
                <a href="#" onClick={() => setShowPendings(true)}>
                  ver.
                </a>
              </strong>
            </Message>
          )}
          <Header as="h2" floated="left" className="header_page">
            Clientes
          </Header>

          <ClientsList
            clients={showPendings ? clientsPendings : clients}
            showPendings={showPendings}
            verify={handleVerify}
            page={activePage}
            totalPages={totalPages}
            handlePaginationChange={handlePaginationChange}
          />
        </>
      )}

      {!hasPermission && <div>No disponible.</div>}
    </div>
  );
};

const mapStateToProps = (state) => ({
  FormUsers: state.form.FormUsers?.values ?? null,
});

const mapDispatchToProps = (dispatch) => ({
  createClient: () => dispatch(createClient()),
  loadClients: (page, pendings,paginate) => dispatch(loadClients(page, pendings,paginate)),
  verifyClient: (id) => dispatch(verifyClient(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Clients);
