import React from 'react';
import { Grid, Button } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Fields } from '../../components';
import { login } from '../../actions';
import logo from '../../assets/images/logo-reestock.png';

const Login = ({ login ,history}) => {
  const handleLogin = () => {
    login();
  };

  return (
    <div className="login">
      <div className="login-images">
        <img src={logo} alt="logo" className="logo" />
      </div>
      <div className="login-form__container">
        <div className="login-form">
          <h1 className="title">Iniciar sesión</h1>
          <h2 className="subtitle">¡Bienvenido a ReeStock!</h2>
          <h5>Revisa tus consumos, recibos y paga en línea desde la comodidad de tu hogar.</h5>

          <Grid divided="vertically">
            <Grid.Row>
              <Grid.Column>
                <div style={{ marginTop: 30 }}>
                  <Form
                    form="loginForm"
                    onSubmit={handleLogin}
                    initialValues={{
                      usuario: '',
                      contraseña: '',
                    }}
                  >
                    <Fields
                      fieldsData={{
                        username: {
                          name: 'usuario',
                          label: 'EMAIL',
                          placeholder: '',
                          type: 'textInline',
                          required: true,
                          disabled: false,
                        },
                        password: {
                          name: 'contraseña',
                          label: 'CONTRASEÑA',
                          placeholder: '',
                          type: 'textInline',
                          typeInput: 'password',
                          required: true,
                          disabled: false,
                        },
                        recordarme: {
                          name: 'recordarme',
                          label: 'Recuérdame',
                          placeholder: '',
                          toggle: false,
                          type: 'checkbox',
                          required: false,
                          disabled: false,
                          styleContainer: {
                            marginTop: 40,
                            position: 'relative',
                          },
                        },
                      }}
                    />

                    <div>
                      <Button className="button-login" type="submit" circular>
                        INGRESAR
                      </Button>
                    </div>
                  </Form>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  login: () => dispatch(login()),
});

export default connect(null, mapDispatchToProps)(withRouter(Login));
